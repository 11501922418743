import React, { useState } from 'react'
import { Form, Input } from '@rocketseat/unform'
import IconSearch from './svg-icons/IconSearch'
import './style.css'

const Search = ({ onSearchChange }) => {
  const [searchText, setSearchText] = useState('')

  const onChange = (e) => {
    setSearchText(e.target.value)
    if (!e.target.value.length) onSearchChange(e.target.value)
  }

  const handleSubmit = (data) => {
    onSearchChange(data.question)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className='minimalist-form single-line search-form'>
      <span className='icon-search'>
        <IconSearch width='28px' height='28px' />
      </span>

      <Input
        value={searchText}
        onChange={onChange}
        name='question'
        type='text'
        placeholder='Type your question…'
        className='input-field'
      />

      <button
        type='submit'
        className='button-blue'
        disabled={!searchText.length}>
        Search
      </button>
    </Form>
  )
}

export default Search
