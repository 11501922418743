import React from 'react'
import classNames from 'classnames'
import { Input, Scope } from '@rocketseat/unform'
import IconZipCode from '../../../../svg-icons/IconZipCode'
import IconHome from '../../../../svg-icons/IconHome'
import IconMapMarker from '../../../../svg-icons/IconMapMarker'
import IconPhone from '../../../../svg-icons/IconPhone'
import './style.scss'

const AdditionalAddresses = ({
  addresses,
  fieldHasError,
  getFieldError,
  storeToForm,
  // setCurrentErrors,
  // currentErrors,
}) => {
  const handleChange = (e, i) => {
    storeToForm({ [e.target.name]: e.target.value }, i)

    // Resetting this particular error (just clean it from arrays) in validation errors object
    // NOTE: Turned off in 'SignupForm' for now!
    // let indexOfChanged = -1
    // currentErrors.inner.map((a, index) => {
    //   if (a.path === e.target.name.split('].').pop()) {
    //     indexOfChanged = index
    //   }
    //   return false
    // })
    // const changableErrorsObject = { ...currentErrors }

    // if (indexOfChanged !== -1) {
    //   changableErrorsObject.inner.splice(indexOfChanged, 1)
    //   changableErrorsObject.errors.splice(indexOfChanged, 1)
    // }
    // setCurrentErrors(changableErrorsObject)
  }

  const additionalAddresses =
    addresses &&
    addresses.map((address, i) => {
      return (
        <Scope path={`additionalAddresses[${i}]`} key={i}>
          <div className='address'>
            <div
              className={classNames('form-row', {
                'with-error': fieldHasError('zipCode1'),
              })}>
              <div className='form-field-with-icon'>
                <IconZipCode />
                <Input
                  name='zipCode'
                  type='text'
                  placeholder='Zip Code'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>{getFieldError('zipCode1')}</div>
              </div>
            </div>
            <div
              className={classNames('form-row duplet', {
                'with-error': fieldHasError('state1') || fieldHasError('city1'),
              })}>
              <div className='form-field-with-icon'>
                <IconMapMarker />
                <Input
                  name='state'
                  type='text'
                  placeholder='State'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>{getFieldError('state1')}</div>
              </div>
              <div className='form-field-with-icon'>
                <IconMapMarker />
                <Input
                  name='city'
                  type='text'
                  placeholder='City'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>{getFieldError('city1')}</div>
              </div>
            </div>
            <div
              className={classNames('form-row', {
                'with-error': fieldHasError('address1'),
              })}>
              <div className='form-field-with-icon'>
                <IconHome />
                <Input
                  name='address'
                  type='text'
                  placeholder='Address'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>{getFieldError('address1')}</div>
              </div>
            </div>
            <div
              className={classNames('form-row duplet', {
                'with-error':
                  fieldHasError('firstPhoneNumber1') ||
                  fieldHasError('secondPhoneNumber1'),
              })}>
              <div className='form-field-with-icon'>
                <IconPhone />
                <Input
                  name='firstPhoneNumber'
                  type='text'
                  placeholder='Phone Number 1'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>
                  {getFieldError('firstPhoneNumber1')}
                </div>
              </div>
              <div className='form-field-with-icon'>
                <IconPhone />
                <Input
                  name='secondPhoneNumber'
                  type='text'
                  placeholder='Phone Number 2'
                  className='input-field'
                  onChange={(e) => handleChange(e, i)}
                />
                <div className='form-error'>
                  {getFieldError('secondPhoneNumber1')}
                </div>
              </div>
            </div>
          </div>
        </Scope>
      )
    })

  return <div>{additionalAddresses}</div>
}

export default AdditionalAddresses
