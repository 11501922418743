import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const zipRegExp = /(^\d{5}(?:[\s]?[-\s][\s]?\d{4})?$)/

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref)
    },
  })
}
Yup.addMethod(Yup.string, 'equalTo', equalTo)

export const formSchema1 = Yup.object().shape({
  name: Yup.string()
    .required('name is required')
    .min(3, 'at least 3 characters long'),
  firstName: Yup.string()
    .required('first name is required')
    .min(3, 'at least 3 characters long'),
  secondName: Yup.string()
    .required('last name is required')
    .min(3, 'at least 3 characters long'),
  email: Yup.string()
    .email()
    .required('email is required'),
  password: Yup.string()
    .required('password is required')
    .min(8, 'at least 8 characters long'),
  repeatedPassword: Yup.string()
    .equalTo(Yup.ref('password'), 'passwords must match')
    .required(),
})

export const formSchema2 = Yup.object().shape({
  zipCode: Yup.string()
    .required('zip is required')
    .matches(zipRegExp, 'zip is not valid')
    .min(3, 'at least 3 characters long'),
  state: Yup.string()
    .required('state is required')
    .min(3, 'at least 3 characters long'),
  city: Yup.string()
    .required('city is required')
    .min(3, 'at least 3 characters long'),
  address: Yup.string()
    .required('address is required')
    .min(3, 'at least 3 characters long'),
  firstPhoneNumber: Yup.string()
    .required('number is required')
    .matches(phoneRegExp, 'number is not valid'),
})

export const formSchema2Address1 = Yup.object().shape({
  zipCode1: Yup.string()
    .required('zip is required')
    .matches(zipRegExp, 'zip is not valid')
    .min(3, 'at least 3 characters long'),
  state1: Yup.string()
    .required('state is required')
    .min(3, 'at least 3 characters long'),
  city1: Yup.string()
    .required('city is required')
    .min(3, 'at least 3 characters long'),
  address1: Yup.string()
    .required('address is required')
    .min(3, 'at least 3 characters long'),  
  firstPhoneNumber1: Yup.string()
    .required('number is required')
    .matches(phoneRegExp, 'number is not valid'),
  secondPhoneNumber1: Yup.string().matches(phoneRegExp, 'number is not valid'),
})

export const formSchema2Address2 = Yup.object().shape({
  zipCode2: Yup.string()
    .required('zip is required')
    .matches(zipRegExp, 'zip is not valid')
    .min(3, 'at least 3 characters long'),
  state2: Yup.string()
    .required('state is required')
    .min(3, 'at least 3 characters long'),
  city2: Yup.string()
    .required('city is required')
    .min(3, 'at least 3 characters long'),
  address2: Yup.string()
    .required('address is required')
    .min(3, 'at least 3 characters long'),
  firstPhoneNumber2: Yup.string()
    .required('number is required')
    .matches(phoneRegExp, 'number is not valid'),
  secondPhoneNumber2: Yup.string().matches(phoneRegExp, 'number is not valid'),
})
