import React from 'react';

const IconRepair = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3634 2.18182L20.7271 0L22.9089 2.18182L20.7271 6.54545H18.5452L16.178 8.91273L13.9961 6.73091L16.3634 4.36364V2.18182ZM22.5925 19.9527L21.0434 21.5018C20.6179 21.9248 19.9307 21.9248 19.5052 21.5018L6.54524 8.56364C6.19049 8.66641 5.82363 8.72144 5.45433 8.72727C4.04509 8.72627 2.72309 8.04475 1.9048 6.89741C1.08652 5.75008 0.872694 4.2782 1.3307 2.94546L4.10161 5.71637L4.67979 5.13818L6.22888 3.58909L6.80706 3.01091L4.03615 0.240002C5.36889 -0.218002 6.84078 -0.00417993 7.98811 0.814106C9.13544 1.63239 9.81696 2.9544 9.81797 4.36364C9.81214 4.73293 9.75711 5.0998 9.65433 5.45455L22.5925 18.4145C23.0155 18.84 23.0155 19.5272 22.5925 19.9527ZM1.40707 18.4145C0.984101 18.84 0.984101 19.5272 1.40707 19.9527L2.95616 21.5018C3.38166 21.9248 4.06885 21.9248 4.49435 21.5018L10.4616 15.5455L7.37435 12.4582L1.40707 18.4145Z'
        fill='black'
      />
      <mask
        id='repairMask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width='22'
        height='22'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.3634 2.18182L20.7271 0L22.9089 2.18182L20.7271 6.54545H18.5452L16.178 8.91273L13.9961 6.73091L16.3634 4.36364V2.18182ZM22.5925 19.9527L21.0434 21.5018C20.6179 21.9248 19.9307 21.9248 19.5052 21.5018L6.54524 8.56364C6.19049 8.66641 5.82363 8.72144 5.45433 8.72727C4.04509 8.72627 2.72309 8.04475 1.9048 6.89741C1.08652 5.75008 0.872694 4.2782 1.3307 2.94546L4.10161 5.71637L4.67979 5.13818L6.22888 3.58909L6.80706 3.01091L4.03615 0.240002C5.36889 -0.218002 6.84078 -0.00417993 7.98811 0.814106C9.13544 1.63239 9.81696 2.9544 9.81797 4.36364C9.81214 4.73293 9.75711 5.0998 9.65433 5.45455L22.5925 18.4145C23.0155 18.84 23.0155 19.5272 22.5925 19.9527ZM1.40707 18.4145C0.984101 18.84 0.984101 19.5272 1.40707 19.9527L2.95616 21.5018C3.38166 21.9248 4.06885 21.9248 4.49435 21.5018L10.4616 15.5455L7.37435 12.4582L1.40707 18.4145Z'
          fill='white'
        />
      </mask>
      <g mask='url(#repairMask0)'>
        <rect width='24' height='24' fill='#191D3A' />
      </g>
    </svg>
  );
};

export default IconRepair;
