import React from 'react';

const IconMenuRepair = ({ fillColor }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.54547 1.27273L12.0909 0L13.3637 1.27273L12.0909 3.81818H10.8182L9.43729 5.19909L8.16456 3.92636L9.54547 2.54545V1.27273ZM13.1791 11.6391L12.2755 12.5427C12.0273 12.7895 11.6264 12.7895 11.3782 12.5427L3.81819 4.99545C3.61126 5.0554 3.39725 5.0875 3.18183 5.09091C2.35977 5.09032 1.5886 4.69277 1.11127 4.02349C0.633937 3.35421 0.509207 2.49561 0.776376 1.71818L2.39274 3.33454L2.73001 2.99727L3.63365 2.09364L3.97092 1.75636L2.35456 0.139999C3.13199 -0.12717 3.99059 -0.0024406 4.65987 0.474893C5.32914 0.952226 5.7267 1.7234 5.72728 2.54545C5.72388 2.76087 5.69178 2.97488 5.63183 3.18182L13.1791 10.7418C13.4258 10.99 13.4258 11.3909 13.1791 11.6391ZM0.820914 10.7418C0.574181 10.99 0.574181 11.3909 0.820914 11.6391L1.72455 12.5427C1.97276 12.7895 2.37362 12.7895 2.62182 12.5427L6.10273 9.06818L4.30182 7.26727L0.820914 10.7418Z'
        fill={fillColor}
      />
      <mask
        id='IconMenuRepairMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='14'
        height='13'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.54547 1.27273L12.0909 0L13.3637 1.27273L12.0909 3.81818H10.8182L9.43729 5.19909L8.16456 3.92636L9.54547 2.54545V1.27273ZM13.1791 11.6391L12.2755 12.5427C12.0273 12.7895 11.6264 12.7895 11.3782 12.5427L3.81819 4.99545C3.61126 5.0554 3.39725 5.0875 3.18183 5.09091C2.35977 5.09032 1.5886 4.69277 1.11127 4.02349C0.633937 3.35421 0.509207 2.49561 0.776376 1.71818L2.39274 3.33454L2.73001 2.99727L3.63365 2.09364L3.97092 1.75636L2.35456 0.139999C3.13199 -0.12717 3.99059 -0.0024406 4.65987 0.474893C5.32914 0.952226 5.7267 1.7234 5.72728 2.54545C5.72388 2.76087 5.69178 2.97488 5.63183 3.18182L13.1791 10.7418C13.4258 10.99 13.4258 11.3909 13.1791 11.6391ZM0.820914 10.7418C0.574181 10.99 0.574181 11.3909 0.820914 11.6391L1.72455 12.5427C1.97276 12.7895 2.37362 12.7895 2.62182 12.5427L6.10273 9.06818L4.30182 7.26727L0.820914 10.7418Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconMenuRepairMask)'>
        <rect width='14' height='14' fill={fillColor} />
      </g>
    </svg>
  );
};

export default IconMenuRepair;
