import React from 'react'

const IconPhone = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.5523 12.5 18 12.9477 18 13.5V17C18 17.5523 17.5523 18 17 18C7.61116 18 0 10.3888 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C5.05228 0 5.5 0.447715 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z'
        fill='black'
      />
      <mask
        id='IconPhoneMask1'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'>
        <path
          d='M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.5523 12.5 18 12.9477 18 13.5V17C18 17.5523 17.5523 18 17 18C7.61116 18 0 10.3888 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C5.05228 0 5.5 0.447715 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconPhoneMask1)'>
        <rect width='18' height='18' fill='#E2E1F1' />
      </g>
    </svg>
  )
}

export default IconPhone
