import React from 'react'
import classNames from 'classnames'
import './style.scss'

const ComingSoon = (props) => {
  return (
    <div
      className={classNames('coming-soon', {
        'non-transparent': props.nonTransparent,
        'slightly-transparent': props.slightlyTransparent,
      })}>
      {props.children}
      <div className='coming-soon--tip'>Coming Soon!</div>
    </div>
  )
}

export default ComingSoon
