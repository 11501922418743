import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import classNames from 'classnames'
import Header from './components/Layout/Header'
import Footer from './components/Layout/Footer'
import Homepage from './components/Homepage'
import AboutUs from './components/AboutUs'
import HowItWorks from './components/HowItWorks'
import FAQ from './components/FAQ'
import ContactUs from './components/ContactUs'
import BecomePartner from './components/BecomePartner'
import SignupContainer from './containers/SignupContainer'
import LoginContainer from './containers/LoginContainer'
import Logout from './containers/LoginContainer/Logout'
import PageNotFound from './components/PageNotFound'
import DashboardContainer from './containers/DashboardContainer'
import { AuthorizedProvider } from './contexts/AuthorizedContext'
import { saveState, loadState } from './contexts/localStorage'
import LoadingSpinner from './components/common/LoadingSpinner'
import TermsAndConditions from './components/TermsAndConditions'
import './App.scss'

const App: React.FC = () => {
  const [fixedLayout, setFixedLayout] = useState(false)
  const [authorized, setAuthorized] = useState(
    loadState() ? loadState().authorized : false
  )

  useEffect(() => {
    saveState({ authorized: authorized })
  }, [authorized])

  return (
    <AuthorizedProvider value={authorized}>
      <Router>
        <div
          className={classNames('layout-wrapper', {
            'fixed-page': fixedLayout,
            'dashboard-background': authorized,
          })}>
          {window.innerWidth <= 990 ? (
              <div className="mobile-container">
                <Header onChangeFixedLayout={setFixedLayout} logoPath='/img/logo-white.svg'/>
                <div className='message-for-mobile-users'>
                  <h2>Sorry for the inconvenience</h2>
                  <br/>
                  <p>We are refreshing our mobile site. In the meantime please email us at <a
                      href='mailto:hello@fendrbendr.com'>hello@fendrbendr.com </a>
                    or give us a call at <a href='tel:833-363-3637'>833.363.3637</a>
                  </p>
                  <br/>
                  <p>
                    Thank you!
                  </p>
                </div>
              </div>
          ) : (
              <>
              <Header onChangeFixedLayout={setFixedLayout}/>

              <main>
                <Switch>
                  <Route path='/aboutus' component={AboutUs}/>
                  <Route path='/howitworks' component={HowItWorks}/>
                  <Route path='/faq' component={FAQ}/>
                  <Route path='/contactus' component={ContactUs}/>
                  <Route path='/become-partner' component={BecomePartner}/>
                  <Route path='/terms-conditions' component={TermsAndConditions}/>
                  <Route path='/signup*' component={SignupContainer}/>

                  {authorized ? (
                      <Redirect exact from='/login' to='/dashboard'/>
                  ) : (
                      <Redirect exact from='/dashboard*' to='/login'/>
                  )}

                  <Route
                      path='/login*'
                      render={() => <LoginContainer setAuthorized={setAuthorized}/>}
                  />
                  <Route path='/dashboard*' component={DashboardContainer}/>
                  <Route
                      path='/logout'
                      render={() => <Logout setAuthorized={setAuthorized}/>}
                  />
                  <Route exact path='/' component={Homepage}/>
                  <Route exact path='/*' component={PageNotFound}/>
                </Switch>
              </main>

              <LoadingSpinner />
              <Footer />
              </>
          )}
        </div>
      </Router>
    </AuthorizedProvider>
  )
}

export default App
