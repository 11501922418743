import React from 'react'

const IconAddPlus = () => {
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32 0C49.6731 0 64 14.3269 64 32C64 40.4869 60.6286 48.6263 54.6274 54.6274C48.6263 60.6286 40.4869 64 32 64C23.5131 64 15.3737 60.6286 9.37258 54.6274C3.37142 48.6263 0 40.4869 0 32C0 23.5131 3.37142 15.3737 9.37258 9.37258C15.3737 3.37142 23.5131 0 32 0ZM31.9999 57.6C46.1119 57.6 57.5999 46.112 57.5999 32C57.5999 17.888 46.1119 6.39997 31.9999 6.39997C17.8879 6.39997 6.39987 17.888 6.39987 32C6.39987 46.112 17.8879 57.6 31.9999 57.6ZM35.2 28.8V16H28.8V28.8H16V35.2H28.8V48H35.2V35.2H48V28.8H35.2Z'
        fill='black'
      />
      <mask
        id='IconAddPlusMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='64'
        height='64'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32 0C49.6731 0 64 14.3269 64 32C64 40.4869 60.6286 48.6263 54.6274 54.6274C48.6263 60.6286 40.4869 64 32 64C23.5131 64 15.3737 60.6286 9.37258 54.6274C3.37142 48.6263 0 40.4869 0 32C0 23.5131 3.37142 15.3737 9.37258 9.37258C15.3737 3.37142 23.5131 0 32 0ZM31.9999 57.6C46.1119 57.6 57.5999 46.112 57.5999 32C57.5999 17.888 46.1119 6.39997 31.9999 6.39997C17.8879 6.39997 6.39987 17.888 6.39987 32C6.39987 46.112 17.8879 57.6 31.9999 57.6ZM35.2 28.8V16H28.8V28.8H16V35.2H28.8V48H35.2V35.2H48V28.8H35.2Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconAddPlusMask)'>
        <rect
          width='64'
          height='64'
          transform='matrix(-1 0 0 1 64 0)'
          fill='#554DF1'
        />
      </g>
    </svg>
  )
}

export default IconAddPlus
