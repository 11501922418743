import React from 'react';

const IconMenuCar = ({ fillColor }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2778 0.777771C11.7911 0.777771 12.2267 1.10444 12.3822 1.55555L14 6.22222V12.4444C14 12.874 13.6518 13.2222 13.2222 13.2222H12.4444C12.0149 13.2222 11.6667 12.874 11.6667 12.4444V11.6667H2.33333V12.4444C2.33333 12.874 1.98511 13.2222 1.55556 13.2222H0.777778C0.348223 13.2222 0 12.874 0 12.4444V6.22222L1.61778 1.55555C1.77333 1.10444 2.20889 0.777771 2.72222 0.777771H11.2778ZM2.72222 1.94444L1.55556 5.44444H12.4444L11.2778 1.94444H2.72222ZM10.1111 8.16666C10.1111 8.81099 10.6334 9.33333 11.2778 9.33333C11.9221 9.33333 12.4444 8.81099 12.4444 8.16666C12.4444 7.52233 11.9221 6.99999 11.2778 6.99999C10.6334 6.99999 10.1111 7.52233 10.1111 8.16666ZM2.72222 9.33333C2.07789 9.33333 1.55556 8.81099 1.55556 8.16666C1.55556 7.52233 2.07789 6.99999 2.72222 6.99999C3.36655 6.99999 3.88889 7.52233 3.88889 8.16666C3.88889 8.81099 3.36655 9.33333 2.72222 9.33333Z'
        fill={fillColor}
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='14'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.2778 0.777771C11.7911 0.777771 12.2267 1.10444 12.3822 1.55555L14 6.22222V12.4444C14 12.874 13.6518 13.2222 13.2222 13.2222H12.4444C12.0149 13.2222 11.6667 12.874 11.6667 12.4444V11.6667H2.33333V12.4444C2.33333 12.874 1.98511 13.2222 1.55556 13.2222H0.777778C0.348223 13.2222 0 12.874 0 12.4444V6.22222L1.61778 1.55555C1.77333 1.10444 2.20889 0.777771 2.72222 0.777771H11.2778ZM2.72222 1.94444L1.55556 5.44444H12.4444L11.2778 1.94444H2.72222ZM10.1111 8.16666C10.1111 8.81099 10.6334 9.33333 11.2778 9.33333C11.9221 9.33333 12.4444 8.81099 12.4444 8.16666C12.4444 7.52233 11.9221 6.99999 11.2778 6.99999C10.6334 6.99999 10.1111 7.52233 10.1111 8.16666ZM2.72222 9.33333C2.07789 9.33333 1.55556 8.81099 1.55556 8.16666C1.55556 7.52233 2.07789 6.99999 2.72222 6.99999C3.36655 6.99999 3.88889 7.52233 3.88889 8.16666C3.88889 8.81099 3.36655 9.33333 2.72222 9.33333Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0)'>
        <rect width='14' height='14' fill={fillColor} />
      </g>
    </svg>
  );
};

export default IconMenuCar;
