import axios from 'axios'
import { getJwtToken } from './session'

const apiHost = 'http://backend-2033510657.us-east-1.elb.amazonaws.com/api'
const getAuthHeaders = () => ({ Authorization: `Bearer ${getJwtToken()}` })

export default {
  login(data) {
    return axios.post(`${apiHost}/auth/signin`, data)
  },

  logout() {
    return axios.delete(`${apiHost}/auth/logout`, {
      headers: getAuthHeaders(),
    })
  },

  businessSignup(data) {
    return axios.post(`${apiHost}/auth/business/signup`, data)
  },

  businessServices() {
    return axios.get(`${apiHost}/businesses/services`)
  }
}
