import React from 'react';

const IconEngine = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='iconEngine'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5064 9C15.5064 9.297 15.4794 9.585 15.4434 9.873L17.3424 11.367C17.5134 11.502 17.5584 11.745 17.4504 11.943L15.6504 15.057C15.5424 15.255 15.2994 15.327 15.1014 15.255L12.8604 14.346C12.3924 14.706 11.9064 15.003 11.3394 15.237L11.0064 17.622C10.9704 17.838 10.7814 18 10.5564 18H6.9564C6.7314 18 6.5424 17.838 6.5064 17.622L6.1734 15.237C5.6064 15.012 5.1204 14.706 4.6524 14.346L2.4114 15.255C2.2134 15.327 1.9704 15.255 1.8624 15.057L0.0623984 11.943C-0.0546016 11.745 -0.00060156 11.502 0.170398 11.367L2.0694 9.873C2.0334 9.585 2.0064 9.297 2.0064 9C2.0064 8.703 2.0334 8.406 2.0694 8.1L0.170398 6.633C-0.00060156 6.498 -0.0546016 6.255 0.0623984 6.057L1.8624 2.943C1.9704 2.745 2.2134 2.664 2.4114 2.745L4.6524 3.645C5.1204 3.294 5.6064 2.988 6.1734 2.763L6.5064 0.378C6.5424 0.162 6.7314 0 6.9564 0H10.5564C10.7814 0 10.9704 0.162 11.0064 0.378L11.3394 2.763C11.9064 2.988 12.3924 3.294 12.8604 3.645L15.1014 2.745C15.2994 2.664 15.5424 2.745 15.6504 2.943L17.4504 6.057C17.5584 6.255 17.5134 6.498 17.3424 6.633L15.4434 8.1C15.4794 8.406 15.5064 8.703 15.5064 9ZM5.6064 9C5.6064 10.7397 7.0167 12.15 8.7564 12.15C10.4961 12.15 11.9064 10.7397 11.9064 9C11.9064 7.2603 10.4961 5.85 8.7564 5.85C7.0167 5.85 5.6064 7.2603 5.6064 9Z'
        fill='black'
      />
      <mask
        id='engineMask1'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.5064 9C15.5064 9.297 15.4794 9.585 15.4434 9.873L17.3424 11.367C17.5134 11.502 17.5584 11.745 17.4504 11.943L15.6504 15.057C15.5424 15.255 15.2994 15.327 15.1014 15.255L12.8604 14.346C12.3924 14.706 11.9064 15.003 11.3394 15.237L11.0064 17.622C10.9704 17.838 10.7814 18 10.5564 18H6.9564C6.7314 18 6.5424 17.838 6.5064 17.622L6.1734 15.237C5.6064 15.012 5.1204 14.706 4.6524 14.346L2.4114 15.255C2.2134 15.327 1.9704 15.255 1.8624 15.057L0.0623984 11.943C-0.0546016 11.745 -0.00060156 11.502 0.170398 11.367L2.0694 9.873C2.0334 9.585 2.0064 9.297 2.0064 9C2.0064 8.703 2.0334 8.406 2.0694 8.1L0.170398 6.633C-0.00060156 6.498 -0.0546016 6.255 0.0623984 6.057L1.8624 2.943C1.9704 2.745 2.2134 2.664 2.4114 2.745L4.6524 3.645C5.1204 3.294 5.6064 2.988 6.1734 2.763L6.5064 0.378C6.5424 0.162 6.7314 0 6.9564 0H10.5564C10.7814 0 10.9704 0.162 11.0064 0.378L11.3394 2.763C11.9064 2.988 12.3924 3.294 12.8604 3.645L15.1014 2.745C15.2994 2.664 15.5424 2.745 15.6504 2.943L17.4504 6.057C17.5584 6.255 17.5134 6.498 17.3424 6.633L15.4434 8.1C15.4794 8.406 15.5064 8.703 15.5064 9ZM5.6064 9C5.6064 10.7397 7.0167 12.15 8.7564 12.15C10.4961 12.15 11.9064 10.7397 11.9064 9C11.9064 7.2603 10.4961 5.85 8.7564 5.85C7.0167 5.85 5.6064 7.2603 5.6064 9Z'
          fill='white'
        />
      </mask>
      <g mask='url(#engineMask1)'>
        <rect width='18' height='18' className='fillLayer' fill='#191D3A' />
      </g>
    </svg>
  );
};

export default IconEngine;
