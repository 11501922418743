import React from 'react';

const IconAppstore = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0915 0C11.2343 0.0342857 10.1972 0.574286 9.57147 1.28571C9.0229 1.93714 8.5429 2.96571 8.67147 3.95143C9.63147 4.02857 10.6086 3.46286 11.2 2.73429C11.8 2.01429 12.2029 1.00286 12.0915 0ZM11.8515 17.1171C13 17.1 13.7543 16.0629 14.4658 15C15.2017 13.9344 15.5468 12.8826 15.6299 12.6293C15.6397 12.5994 15.6459 12.5806 15.6486 12.5743C15.6229 12.5657 13.3772 11.7 13.3515 9.11143C13.3349 7.01791 14.9959 5.97087 15.1803 5.85463C15.1869 5.85046 15.1917 5.84749 15.1943 5.84571C14.1915 4.37143 12.6315 4.17429 12.0743 4.14857C11.26 4.06451 10.4682 4.37654 9.8233 4.63068C9.41606 4.79117 9.06739 4.92857 8.80862 4.92857C8.51392 4.92857 8.14941 4.78408 7.74123 4.62228C7.21152 4.4123 6.60827 4.17317 5.98862 4.18286C4.54004 4.20857 3.2029 5.03143 2.45719 6.33429C0.948615 8.95714 2.07147 12.8571 3.53719 14.9743C4.25719 16.0114 5.10576 17.1857 6.22862 17.1429C6.7345 17.1229 7.09229 16.9697 7.462 16.8114C7.88784 16.6291 8.32949 16.44 9.03147 16.44C9.69522 16.44 10.1165 16.6224 10.5219 16.798C10.9123 16.9671 11.288 17.1298 11.8515 17.1171Z'
        fill='black'
      />
      <mask
        id='appStoreMask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width='15'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0915 0C11.2343 0.0342857 10.1972 0.574286 9.57147 1.28571C9.0229 1.93714 8.5429 2.96571 8.67147 3.95143C9.63147 4.02857 10.6086 3.46286 11.2 2.73429C11.8 2.01429 12.2029 1.00286 12.0915 0ZM11.8515 17.1171C13 17.1 13.7543 16.0629 14.4658 15C15.2017 13.9344 15.5468 12.8826 15.6299 12.6293C15.6397 12.5994 15.6459 12.5806 15.6486 12.5743C15.6229 12.5657 13.3772 11.7 13.3515 9.11143C13.3349 7.01791 14.9959 5.97087 15.1803 5.85463C15.1869 5.85046 15.1917 5.84749 15.1943 5.84571C14.1915 4.37143 12.6315 4.17429 12.0743 4.14857C11.26 4.06451 10.4682 4.37654 9.8233 4.63068C9.41606 4.79117 9.06739 4.92857 8.80862 4.92857C8.51392 4.92857 8.14941 4.78408 7.74123 4.62228C7.21152 4.4123 6.60827 4.17317 5.98862 4.18286C4.54004 4.20857 3.2029 5.03143 2.45719 6.33429C0.948615 8.95714 2.07147 12.8571 3.53719 14.9743C4.25719 16.0114 5.10576 17.1857 6.22862 17.1429C6.7345 17.1229 7.09229 16.9697 7.462 16.8114C7.88784 16.6291 8.32949 16.44 9.03147 16.44C9.69522 16.44 10.1165 16.6224 10.5219 16.798C10.9123 16.9671 11.288 17.1298 11.8515 17.1171Z'
          fill='white'
        />
      </mask>
      <g mask='url(#appStoreMask0)'>
        <rect width='18' height='18' fill='#191d3a' />
      </g>
    </svg>
  );
};

export default IconAppstore;
