import React from 'react'

const IconConfirmedCheck = () => {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='100' height='100' rx='50' fill='#62E5A5' fillOpacity='0.2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50 30C39 30 30 39 30 50C30 61 39 70 50 70C61 70 70 61 70 50C70 39 61 30 50 30ZM50.0001 66C41.1801 66 34.0001 58.82 34.0001 50C34.0001 41.18 41.1801 34 50.0001 34C58.8201 34 66.0001 41.18 66.0001 50C66.0001 58.82 58.8201 66 50.0001 66ZM46.0001 54.34L59.1801 41.16L62.0001 44L46.0001 60L38.0001 52L40.8201 49.18L46.0001 54.34Z'
        fill='black'
      />
      <mask
        id='IconConfirmedCheckMask0'
        masktype='alpha'
        maskUnits='userSpaceOnUse'
        x='30'
        y='30'
        width='40'
        height='40'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M50 30C39 30 30 39 30 50C30 61 39 70 50 70C61 70 70 61 70 50C70 39 61 30 50 30ZM50.0001 66C41.1801 66 34.0001 58.82 34.0001 50C34.0001 41.18 41.1801 34 50.0001 34C58.8201 34 66.0001 41.18 66.0001 50C66.0001 58.82 58.8201 66 50.0001 66ZM46.0001 54.34L59.1801 41.16L62.0001 44L46.0001 60L38.0001 52L40.8201 49.18L46.0001 54.34Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconConfirmedCheckMask0)'>
        <rect x='30' y='30' width='40' height='40' fill='#62E5A5' />
      </g>
    </svg>
  )
}

export default IconConfirmedCheck
