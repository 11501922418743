import React, { useState, useEffect } from 'react'
import { Form } from '@rocketseat/unform'
import { withRouter } from 'react-router-dom'
import { initialDataSchema, formStepsNames } from './formData'
import { formSchema1, formSchema2 } from './validationSchemas'
import FormSteps from '../../../../components/common/forms/FormSteps'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import api from '../../../../api'
import { trackPromise } from 'react-promise-tracker'
import * as Yup from 'yup'

const SignupForm = ({ history }) => {
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState(initialDataSchema)
  const [errors, setErrors] = useState({})
  const [serverError, setServerError] = useState('')

  const addValidationErrors = (incomingErrors) => {
    if (incomingErrors instanceof Yup.ValidationError) {
      if (errors.errors && errors.inner) {
        setErrors({
          ...errors,
          ...{
            errors: [...errors.errors, ...incomingErrors.errors],
          },
          ...{
            inner: [...errors.inner, ...incomingErrors.inner],
          },
        })
      } else {
        setErrors({
          ...errors,
          ...{
            errors: [...incomingErrors.errors],
          },
          ...{
            inner: [...incomingErrors.inner],
          },
        })
      }
    }
  }

  const validate = async () => {
    // Remove all previous errors
    await setErrors({})

    try {
      if (step === 1) {
        await formSchema1.validate(formData, {
          abortEarly: false,
        })
      }
      if (step === 2) {
        await formSchema2.validate(formData, {
          abortEarly: false,
        })
      }

      // Switch the form step if validation passed at this time point
      if (step < 3 && (!errors.inner || !errors.inner.length)) {
        setStep(step + 1)
      }
    } catch (err) {
      // Or set validation errors
      await addValidationErrors(err)
    }

    // Validation for additional addresses (optional now)

    // if (formData.additionalAddresses.length >= 1) {
    //   formData.additionalAddresses.forEach((address, i) => {
    //     return (async function() {
    //       console.log('formData.additionalAddresses foreach with', address)
    //       try {
    //         await formSchema2Address1.validate(
    //           formData.additionalAddresses[i],
    //           {
    //             abortEarly: false,
    //           }
    //         )
    //       } catch (addressErr) {
    //         // Set additional addresses validation errors
    //         await addValidationErrors(addressErr)
    //       }
    //       console.log('add address check is done')

    //       return false
    //     })()
    //   })
    // }
  }

  const resetForm = () => {
    setFormData(initialDataSchema)
  }

  const storeToForm = async (changedData, i) => {
    if (`openningHours[${i}].isOpen` in changedData) {
      let openningHours = formData.openningHours
      openningHours[i].isOpen = changedData[`openningHours[${i}].isOpen`]

      setFormData({ ...formData, openningHours })
      return false
    } else if (`services[${i}].value` in changedData) {
      let services = formData.services
      services[i].isProvided = changedData[`services[${i}].value`]
      setFormData({ ...formData, services })
      return false
    } else if (
      Object.keys(changedData)[0].includes(`additionalAddresses[${i}]`)
    ) {
      const additionalAddresses = [...formData.additionalAddresses]
      const length = Object.keys(changedData)[0].length
      const preparedKey = Object.keys(changedData)[0].substring(23, length)
      const preparedData = { [preparedKey]: Object.values(changedData)[0] }
      additionalAddresses[i] = { ...additionalAddresses[i], ...preparedData }
      setFormData({ ...formData, additionalAddresses })
      return false
    } else if ('additionalAddresses' in changedData && i > 0) {
      const updatedAddresses = [...formData.additionalAddresses]
      updatedAddresses.push(changedData.additionalAddresses)
      setFormData({ ...formData, additionalAddresses: updatedAddresses })
      return false
    } else {
      await setFormData({ ...formData, ...changedData })
    }
    if (serverError !== '') {
      setServerError('')
    }
  }

  const handleSubmit = () => {
    let dataForSend = { ...formData }
    let address = {
      zipCode: formData.zipCode,
      state: formData.state,
      city: formData.city,
      address: formData.address,
      firstPhoneNumber: formData.firstPhoneNumber,
      secondPhoneNumber: formData.secondPhoneNumber,
      longitude: 0,
      latitude: 0,
    }
    if (!dataForSend.addresses.length) {
      dataForSend.addresses.push(address)
    }
    delete dataForSend.zipCode
    delete dataForSend.state
    delete dataForSend.city
    delete dataForSend.address
    delete dataForSend.longitude
    delete dataForSend.repeatedPassword

    formData.additionalAddresses.map((a) => {
      const AddressIsNotEmpty =
        Object.values(a).filter((x, i) => {
          return x.length
        }).length >= 5
      let address = { ...a }
      address.longitude = 0
      address.latitude = 0
      if (AddressIsNotEmpty) dataForSend.addresses.push(address)
      return false
    })
    delete dataForSend.additionalAddresses
    delete dataForSend.firstPhoneNumber
    delete dataForSend.secondPhoneNumber

    const chosenServicesIDs = []
    dataForSend.services.map((s) => {
      if (s.isProvided) {
        chosenServicesIDs.push(s.id)
      }
      return false
    })
    dataForSend.services = chosenServicesIDs

    const closedDaysIndexes = []
    dataForSend.openningHours.map((item, index) => {
      if (!item.isOpen) {
        closedDaysIndexes.push(index)
      }
      return false
    })

    closedDaysIndexes.map((i) => {
      dataForSend.openningHours[i].closeTimeHours = null
      dataForSend.openningHours[i].closeTimeMinutes = null
      dataForSend.openningHours[i].openTimeHours = null
      dataForSend.openningHours[i].openTimeMinutes = null
      return false
    })

    console.log('Data for send: ', dataForSend)

    trackPromise(
      api
        .businessSignup(dataForSend)
        .then((res) => {
          resetForm()
          history.push('/signup-business/welcome')
          console.log('Submitted successfully: ', res)
        })
        .catch(async (err) => {
          const error = await JSON.parse(JSON.stringify(err)).message
          if (error === 'Request failed with status code 400') {
            setServerError(
              'Such user exists or something went wrong, please contact us'
            )
          } else {
            setServerError(error)
          }
        })
    )
  }

  useEffect(() => {
    if (!formData.services) {
      trackPromise(
        api
          .businessServices()
          .then(async ({ data }) => {
            setFormData({
              ...formData,
              ...{ services: data.data },
            })
          })
          .catch((err) => {
            const error = JSON.parse(JSON.stringify(err)).message
            setServerError(error)
          })
      )
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        style={step === 2 || step === 3 ? { marginLeft: '-40px' } : {}}
        className='form-heading-block'>
        {step !== 2 && step !== 3 && (
          <h4 className='form-heading'>Create an Account</h4>
        )}

        <FormSteps setStep={setStep} step={step} steps={formStepsNames} />
      </div>

      <Form onSubmit={handleSubmit} initialData={formData}>
        {step === 1 && (
          <FormStep1
            setStep={setStep}
            step={step}
            storeToForm={storeToForm}
            errors={errors}
            validate={validate}
            setErrors={setErrors}
          />
        )}
        {step === 2 && (
          <FormStep2
            setStep={setStep}
            step={step}
            storeToForm={storeToForm}
            workingHours={formData.openningHours}
            formData={formData}
            errors={errors}
            validate={validate}
            setErrors={setErrors}
          />
        )}
        {step === 3 && (
          <FormStep3
            setStep={setStep}
            step={step}
            storeToForm={storeToForm}
            services={formData.services}
            serverError={serverError}
          />
        )}
      </Form>
    </>
  )
}

export default withRouter(SignupForm)
