import React, { useContext } from 'react'
import classNames from 'classnames'
import { withRouter, Link } from 'react-router-dom'
import SidebarRight from './SidebarRight'
import AuthorizedContext from '../../../contexts/AuthorizedContext'
import './style.css'

const Header = ({ location, onChangeFixedLayout, logoPath }) => {
  const authorized = useContext(AuthorizedContext)

  return (
    <header>
      <div className='wide-container'>
          <Link to='/' className='logo-link'>
              {logoPath ? <img src={logoPath} className='logo' alt='logo'/> :

                  location.pathname === '/howitworks' ||
                  location.pathname.includes('/signup-') ||
                  location.pathname.includes('/login') ? (
                      <img src='/img/logo-white.svg' className='logo' alt='logo'/>
                  ) : (
                      <img src='/img/logo.svg' className='logo' alt='logo'/>
                  )}
          </Link>
        {authorized ? (
          
          <span className='sign-buttons'>
            <Link
              to='/get-quote'
              className={classNames('button', {
                'button-red': location.pathname === '/howitworks',
                'button-blue': location.pathname !== '/howitworks',
              })}
            >
              Get a Quote
            </Link>
          </span>
        ) : (
          location.pathname !== '/signup' &&
          !location.pathname.includes('/signup-') &&
          !location.pathname.includes('/login') && (
            <span className='sign-buttons'>
              <Link
                to='/login'
                className={classNames('button', {
                  'button-red': location.pathname === '/howitworks',
                  'button-gray': location.pathname !== '/howitworks',
                })}
              >
                Login
              </Link>
              <Link to='/signup' className='button button-blue'>
                Sign Up
              </Link>
            </span>
          )
        )}

        <SidebarRight onChangeFixedLayout={onChangeFixedLayout} />
      </div>
    </header>
  )
}

export default withRouter(Header)
