import React from 'react';

const IconTwitter = ({ fillColor }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.661 16.629C12.453 16.629 16.168 11.001 16.168 6.121C16.168 5.962 16.165 5.803 16.157 5.644C16.879 5.123 17.505 4.473 18 3.732C17.338 4.025 16.626 4.224 15.879 4.314C16.641 3.857 17.226 3.133 17.503 2.271C16.789 2.693 15.999 3.001 15.158 3.167C14.484 2.449 13.524 2 12.462 2C10.423 2 8.768 3.654 8.768 5.692C8.768 5.983 8.802 6.265 8.864 6.535C5.796 6.381 3.074 4.911 1.253 2.677C0.935 3.222 0.753 3.857 0.753 4.532C0.753 5.814 1.404 6.944 2.395 7.607C1.79 7.588 1.221 7.421 0.724 7.145C0.723 7.16 0.723 7.176 0.723 7.191C0.723 8.981 1.995 10.474 3.685 10.813C3.375 10.897 3.049 10.942 2.712 10.942C2.473 10.942 2.242 10.918 2.017 10.875C2.487 12.343 3.85 13.41 5.468 13.441C4.203 14.431 2.611 15.022 0.881 15.022C0.583 15.022 0.289 15.004 0 14.97C1.634 16.018 3.575 16.629 5.661 16.629Z'
        fill='#262628'
      />
      <mask
        id='twitterMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='2'
        width='18'
        height='15'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.661 16.629C12.453 16.629 16.168 11.001 16.168 6.121C16.168 5.962 16.165 5.803 16.157 5.644C16.879 5.123 17.505 4.473 18 3.732C17.338 4.025 16.626 4.224 15.879 4.314C16.641 3.857 17.226 3.133 17.503 2.271C16.789 2.693 15.999 3.001 15.158 3.167C14.484 2.449 13.524 2 12.462 2C10.423 2 8.768 3.654 8.768 5.692C8.768 5.983 8.802 6.265 8.864 6.535C5.796 6.381 3.074 4.911 1.253 2.677C0.935 3.222 0.753 3.857 0.753 4.532C0.753 5.814 1.404 6.944 2.395 7.607C1.79 7.588 1.221 7.421 0.724 7.145C0.723 7.16 0.723 7.176 0.723 7.191C0.723 8.981 1.995 10.474 3.685 10.813C3.375 10.897 3.049 10.942 2.712 10.942C2.473 10.942 2.242 10.918 2.017 10.875C2.487 12.343 3.85 13.41 5.468 13.441C4.203 14.431 2.611 15.022 0.881 15.022C0.583 15.022 0.289 15.004 0 14.97C1.634 16.018 3.575 16.629 5.661 16.629Z'
          fill='white'
        />
      </mask>
      <g mask='url(#twitterMask)'>
        <rect width='18' height='18' fill={fillColor} />
      </g>
    </svg>
  );
};

export default IconTwitter;
