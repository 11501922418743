import React from 'react'
import Card from '../../common/Card'
import IconArrowDown from './svg-icons/IconArrowDown'

const FAQList = ({ list, classes }) => {
  return (
    <div className={classes}>
      <h2 className='blue-heading'>
        Browse through most frequently asked questions
      </h2>

      {list.map((item, i) => (
        <Card key={i}>
          <details>
            <summary>
              <h3>{item.question}</h3>
              <IconArrowDown fillColor='#554DF1' />
            </summary>
            <p>{item.answer}</p>
          </details>
        </Card>
      ))}
    </div>
  )
}

export default FAQList
