import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Input } from '@rocketseat/unform'
import IconUser from '../../../../../components/common/forms/ContactUsForm/svg-icons/IconUser'
import IconMail from '../../../../../components/common/forms/ContactUsForm/svg-icons/IconMail'
import IconLinkArrow from '../../../../../components/svg-icons/IconLinkArrow'
import IconLock from '../../../svg-icons/IconLock'
import IconHome from '../../../svg-icons/IconHome'

interface ErrorsInner {
  path: String
  message: String
}

type StepParams = {
  setStep: any
  step: number
  storeToForm: Function
  errors: any
  validate: Function
  setErrors: Function
}

const FormStep1: Function = ({
  setStep,
  step,
  storeToForm,
  errors,
  validate,
  setErrors,
}: StepParams) => {
  const [currentErrors, setCurrentErrors] = useState(errors)

  const handleChange = (e) => {
    storeToForm({ [e.target.name]: e.target.value })
    // Cleanup fields and server error if it exists
    cleanFieldError(e.target.name)
  }

  const fieldHasError = (fieldName) => {
    return (
      currentErrors.inner &&
      currentErrors.inner.filter((i) => i.path === fieldName).length
    )
  }

  const getFieldError = (fieldName) => {
    let error
    if (
      currentErrors.inner &&
      currentErrors.inner.filter((i) => i.path === fieldName).length
    ) {
      error = currentErrors.inner.filter((i) => i.path === fieldName)[0].message
    }
    return error
  }

  const cleanFieldError = (fieldName) => {
    if (currentErrors.inner && currentErrors.inner.length) {
      let updatedErrors = { ...currentErrors }
      let indexOfRemovable
      updatedErrors.inner = currentErrors.inner.filter((inner, i) => {
        if (inner.path === fieldName) indexOfRemovable = i
        return inner.path !== fieldName
      })
      updatedErrors.errors = currentErrors.errors.filter((error, i) => {
        return i !== indexOfRemovable
      })
      setCurrentErrors(updatedErrors)
      setErrors(updatedErrors)
    }
  }

  const toNextStep = async () => {
    await validate()
  }

  useEffect(() => {
    // Setting errors only if they exist
    if (Object.keys(errors).length > 0) {
      setCurrentErrors(errors)
    }
    // eslint-disable-next-line
  }, [errors])

  return (
    <>
      <div className='form-step-content form-step-1-content'>
        <div
          className={classNames('form-row', {
            'with-error': fieldHasError('name'),
          })}>
          <div className='form-field-with-icon'>
            <IconHome />
            <Input
              name='name'
              type='text'
              placeholder='Name of Business'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-error'>{getFieldError('name')}</div>
        </div>
        <div
          className={classNames('form-row duplet', {
            'with-error':
              fieldHasError('firstName') || fieldHasError('secondName'),
          })}>
          <div className='form-field-with-icon'>
            <IconUser />
            <Input
              name='firstName'
              type='text'
              placeholder='Contact First Name'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
            <div className='form-error'>{getFieldError('firstName')}</div>
          </div>
          <div className='form-field-with-icon'>
            <IconUser />
            <Input
              name='secondName'
              type='text'
              placeholder='Contact Last Name'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
            <div className='form-error'>{getFieldError('secondName')}</div>
          </div>
        </div>
        <div
          className={classNames('form-row', {
            'with-error': fieldHasError('email'),
          })}>
          <div className='form-field-with-icon'>
            <IconMail />
            <Input
              name='email'
              type='text'
              placeholder='Email'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
            <div className='form-error'>{getFieldError('email')}</div>
          </div>
        </div>
        <div
          className={classNames('form-row duplet', {
            'with-error':
              fieldHasError('password') || fieldHasError('repeatedPassword'),
          })}>
          <div className='form-field-with-icon'>
            <IconLock />
            <Input
              name='password'
              type='password'
              placeholder='Password'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
            <div className='form-error'>{getFieldError('password')}</div>
          </div>
          <div className='form-field-with-icon'>
            <IconLock />
            <Input
              name='repeatedPassword'
              type='password'
              placeholder='Confirm Password'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
            <div className='form-error'>
              {getFieldError('repeatedPassword')}
            </div>
          </div>
        </div>

        <span className='button button-blue with-icon' onClick={toNextStep}>
          Next
          <IconLinkArrow fillColor='white' />
        </span>
      </div>
    </>
  )
}

export default FormStep1
