import React from 'react'
import './style.scss'
import ScrollToTop from '../ScrollToTop/index';

const TermsAndConditions = (props) => {

    const [showTerms, setShowTerms] = React.useState(props.location.state ? props.location.state.showTerms : true);

    return (
        <>
        <ScrollToTop/>
        <div className='terms-page'>
            <section className='light-gray-section'>
                <div className='narrow-container'>
                    <div className='app-description'>
                        <h1>{showTerms ? 'Terms and Conditions' : 'Privacy Policy'}</h1>
                        <p>Last updated: January 20, 2020</p>
                    </div>
                </div>
            </section>

            <main>
                <div className='container'>
                    <div className='sidebar'>
                        <span className={`sidebar--item ${showTerms ? 'active' : ''}`} onClick={() => setShowTerms(true)}>Terms of Use</span>
                        <span className={`sidebar--item ${!showTerms ? 'active' : ''}`} onClick={() => setShowTerms(false)}>Privacy Policy</span>
                    </div>
                    <section className={`${showTerms ? 'active' : ''}`}>
                        <p>
                            Please read these terms and conditions carefully before using Our
                            Service.
                        </p>
                        <h4 className='blue-heading no-margin'>
                            Interpretation and Definitions
                        </h4>

                        <h4>Interpretation</h4>
                        <p>
                            The words of which the initial letter is capitalized have meanings
                            defined under the following conditions.
                        </p>
                        <p>
                            The following definitions shall have the same meaning regardless
                            of whether they appear in singular or in plural.
                        </p>

                        <h4>Definitions </h4>

                        <p>For the purposes of these Terms and Conditions:</p>
                        <p>
                            <strong>Application</strong> means the software program provided
                            by the Company downloaded by you on any electronic device, named
                            Fendr Bendr.
                        </p>
                        <p>
                            <strong>Application Store</strong> means the digital distribution
                            service operated and developed by Apple Inc. (Apple App Store) or
                            Google Inc. (Google Play Store) in which the Application has been
                            downloaded.{' '}
                        </p>
                        <p>
                            <strong>Affiliate</strong> means an entity that controls, is
                            controlled by or is under common control with a party, where
                            “control” means ownership of 50% or more of the shares, equity
                            interest or other securities entitled to vote for election of
                            directors or other managing authority.
                        </p>
                        <p>
                            <strong>Account</strong> means a unique account created for You to
                            access our Service or parts of our Service.
                        </p>
                        <p>
                            <strong>Company</strong> (referred to as either “the Company”,
                            “We”, “Us” or “Our” in this Agreement) refers to Fendr Bendr, Inc.
                            , 79 Madison Avenue, 8 Floor, New York, NY 10016. Content refers
                            to content such as text, images, or other information that can be
                            posted, uploaded, linked to or otherwise made available by You,
                            regardless of the form of that content.
                        </p>
                        <p>
                            <strong>Country refers to</strong>: New York, United States
                        </p>
                        <p>
                            <strong>Device</strong> means any device that can access the
                            Service such as a computer, a cellphone or a digital tablet.
                        </p>
                        <p>
                            <strong>Feedback</strong> means feedback, innovations or
                            suggestions sent by You regarding the attributes, performance or
                            features of our Service.
                        </p>
                        <p>
                            <strong>Goods</strong> refer to the items offered for sale on the
                            Service.
                        </p>
                        <p>
                            <strong>Orders</strong> mean a request by You to purchase Goods
                            from Us.
                        </p>
                        <p>
                            <strong>In-app Purchase</strong> refers to the purchase of a
                            product, item, service or Subscription made through the
                            Application and subject to these Terms and Conditions and/or the
                            Application Store’s own terms and conditions.
                        </p>
                        <p>
                            <strong>Promotions</strong> refer to contests, sweepstakes or
                            other promotions offered through the Service.
                        </p>
                        <p>
                            <strong>Service</strong> refers to the Application or the Website
                            or both.
                        </p>
                        <p>
                            <strong>Terms and Conditions</strong> (also referred as “Terms”)
                            mean these Terms and Conditions that form the entire agreement
                            between You and the Company regarding the use of the Service.
                        </p>
                        <p>
                            <strong>Third-party Social Media Service</strong> means any
                            services or content (including data, information, products or
                            services) provided by a third-party that may be displayed,
                            included or made available by the Service.
                        </p>
                        <p>
                            <strong>Website</strong> refers to Fendr Bendr, accessible from
                            http://www.fendrbendr.com You means the individual accessing or
                            using the Service, or the company, or other legal entity on behalf
                            of which such individual is accessing or using the Service, as
                            applicable.
                        </p>

                        <h4>Acknowledgement</h4>
                        <p>
                            These are the Terms and Conditions governing the use of this
                            Service and the agreement that operates between You and the
                            Company. These Terms and Conditions set out the rights and
                            obligations of all users regarding the use of the Service.
                        </p>
                        <p>
                            Your access to and use of the Service is conditioned on Your
                            acceptance of and compliance with these Terms and Conditions.
                            These Terms and Conditions apply to all visitors, users and others
                            who access or use the Service.
                        </p>
                        <p>
                            By accessing or using the Service You agree to be bound by these
                            Terms and Conditions. If You disagree with any part of these Terms
                            and Conditions then You may not access the Service.
                        </p>
                        <p>
                            Your access to and use of the Service is also conditioned on Your
                            acceptance of and compliance with the Privacy Policy of the
                            Company. Our Privacy Policy describes Our policies and procedures
                            on the collection, use and disclosure of Your personal information
                            when You use the Application or the Website and tells You about
                            Your privacy rights and how the law protects You. Please read Our
                            Privacy Policy carefully before using Our Service.
                        </p>

                        <h4>Placing Orders for Goods</h4>
                        <p>
                            By placing an Order for Goods through the Service, You warrant
                            that You are legally capable of entering into binding contracts.
                        </p>

                        <h4>Your Information</h4>
                        <p>
                            If You wish to place an Order for Goods available on the Service,
                            You may be asked to supply certain information relevant to Your
                            Order including, without limitation, Your name, Your credit card
                            number, the expiration date of Your credit card, Your billing
                            address, and Your shipping information.
                        </p>
                        <p>
                            You represent and warrant that: (i) You have the legal right to
                            use any credit or debit card(s) or other payment method(s) in
                            connection with any Order; and that (ii) the information You
                            supply to us is true, correct and complete.
                        </p>
                        <p>
                            By submitting such information, You grant us the right to provide
                            the information to payment processing third parties for purposes
                            of facilitating the completion of Your Order.
                        </p>

                        <h4>Order Cancellation </h4>
                        <p>
                            We reserve the right to refuse or cancel Your Order at any time
                            for certain reasons including but not limited to:
                            <ul>
                                <li>- Goods availability</li>
                                <li>- Errors in the description or prices for Goods</li>
                                <li>- Errors in Your Order</li>
                            </ul>
                        </p>
                        <p>
                            We reserve the right to refuse or cancel Your Order if fraud or an
                            unauthorized or illegal transaction is suspected.
                        </p>

                        <h4>Your Order Cancellation Rights </h4>
                        <p>
                            Any Goods you purchase can only be returned in accordance with
                            these Terms and Conditions and Our Returns Policy.
                        </p>
                        <p>
                            Our Returns Policy forms a part of these Terms and Conditions.
                            Please read our Returns Policy to learn more about your right to
                            cancel Your Order.
                        </p>
                        <p>
                            Your right to cancel an Order only applies to Goods that are
                            returned in the same condition as You received them. You should
                            also include all of the products instructions, documents and
                            wrappings. Goods that are damaged or not in the same condition as
                            You received them or which are worn simply beyond opening the
                            original packaging will not be refunded. You should therefore take
                            reasonable care of the purchased Goods while they are in Your
                            possession.
                        </p>
                        <p>
                            We will reimburse You no later than 14 days from the day on which
                            We receive Your order cancellation notice. We will use the same
                            means of payment as You used for the Order, and You will not incur
                            any fees for such reimbursement.
                        </p>
                        <p>
                            You will not have any right to cancel an Order for the supply of
                            any of the following Goods:
                        </p>
                        <p>
                            <ul>
                                <li>
                                    - The supply of Goods made to Your specifications or clearly
                                    personalized.
                                </li>
                                <li>
                                    - The supply of Goods which according to their nature are not
                                    suitable to be returned, deteriorate rapidly or where the date
                                    of expiry is over.
                                </li>
                                <li>
                                    - The supply of Goods which are not suitable for return due to
                                    health protection or hygiene reasons and were unsealed after
                                    delivery.
                                </li>
                                <li>
                                    - The supply of Goods which are, after delivery, according to
                                    their nature, inseparably mixed with other items.
                                </li>
                                <li>
                                    - The supply of digital content which is not supplied on a
                                    tangible medium if the performance has begun with Your prior
                                    express consent and You have acknowledged Your loss of
                                    cancellation right.
                                </li>
                            </ul>
                        </p>

                        <h4>Availability, Errors and Inaccuracies </h4>
                        <p>
                            We are constantly updating Our offerings of Goods on the Service.
                            The Goods available on Our Service may be mispriced, described
                            inaccurately, or unavailable, and We may experience delays in
                            updating information regarding our Goods on the Service and in Our
                            advertising on other websites.
                        </p>
                        <p>
                            We cannot and do not guarantee the accuracy or completeness of any
                            information, including prices, product images, specifications,
                            availability, and services. We reserve the right to change or
                            update information and to correct errors, inaccuracies, or
                            omissions at any time without prior notice.
                        </p>

                        <h4>Prices Policy</h4>
                        <p>
                            The Company reserves the right to revise its prices at any time
                            prior to accepting an Order.
                        </p>
                        <p>
                            The prices quoted may be revised by the Company subsequent to
                            accepting an Order in the event of any occurrence affecting
                            delivery caused by government action, variation in customs duties,
                            increased shipping charges, higher foreign exchange costs and any
                            other matter beyond the control of the Company. In that event, You
                            will have the right to cancel Your Order.
                        </p>

                        <h4>Payments</h4>
                        <p>
                            All Goods purchased are subject to a one-time payment. Payment can
                            be made through various payment methods we have available, such as
                            Visa, MasterCard, Affinity Card, American Express cards or online
                            payment methods (PayPal, for example).
                        </p>
                        <p>
                            Payment cards (credit cards or debit cards) are subject to
                            validation checks and authorization by Your card issuer. If we do
                            not receive the required authorization, We will not be liable for
                            any delay or non-delivery of Your Order.
                        </p>

                        <h4>In-app Purchases </h4>
                        <p>
                            The Application may include In-app Purchases that allow you to buy
                            products, services or Subscriptions.
                        </p>
                        <p>
                            More information about how you may be able to manage In-app
                            Purchases using your Device may be set out in the Application
                            Store's own terms and conditions or in your Device's Help
                            settings.
                        </p>
                        <p>
                            In-app Purchases can only be consumed within the Application. If
                            you make a In-app Purchase, that In-app Purchase cannot be
                            cancelled after you have initiated its download. In-app Purchases
                            cannot be redeemed for cash or other consideration or otherwise
                            transferred.
                        </p>
                        <p>
                            If any In-app Purchase is not successfully downloaded or does not
                            work once it has been successfully downloaded, we will, after
                            becoming aware of the fault or being notified to the fault by You,
                            investigate the reason for the fault. We will act reasonably in
                            deciding whether to provide You with a replacement In-app Purchase
                            or issue You with a patch to repair the fault. In no event will We
                            charge You to replace or repair the In-app Purchase. In the
                            unlikely event that we are unable to replace or repair the
                            relevant In-app Purchase or are unable to do so within a
                            reasonable period of time and without significant inconvenience to
                            You, We will authorize the Application Store to refund You an
                            amount up to the cost of the relevant In-app Purchase.
                            Alternatively, if You wish to request a refund, You may do so by
                            contacting the Application Store directly.
                        </p>
                        <p>
                            You acknowledge and agree that all billing and transaction
                            processes are handled by the Application Store from where you
                            downloaded the Application and are governed by that Application
                            Store's own terms and conditions.
                        </p>
                        <p>
                            If you have any payment related issues with In-app Purchases, then
                            you need to contact the Application Store directly.
                        </p>

                        <h4>Promotions</h4>
                        <p>
                            Any Promotions made available through the Service may be governed
                            by rules that are separate from these Terms.
                        </p>
                        <p>
                            If You participate in any Promotions, please review the applicable
                            rules as well as our Privacy policy. If the rules for a Promotion
                            conflict with these Terms, the Promotion rules will apply.
                        </p>

                        <h4>User Accounts </h4>
                        <p>
                            When You create an account with Us, You must provide Us
                            information that is accurate, complete, and current at all times.
                            Failure to do so constitutes a breach of the Terms, which may
                            result in immediate termination of Your account on Our Service.
                        </p>
                        <p>
                            You are responsible for safeguarding the password that You use to
                            access the Service and for any activities or actions under Your
                            password, whether Your password is with Our Service or a
                            Third-Party Social Media Service.
                        </p>
                        <p>
                            You agree not to disclose Your password to any third party. You
                            must notify Us immediately upon becoming aware of any breach of
                            security or unauthorized use of Your account.
                        </p>
                        <p>
                            You may not use as a username the name of another person or entity
                            or that is not lawfully available for use, a name or trademark
                            that is subject to any rights of another person or entity other
                            than You without appropriate authorization, or a name that is
                            otherwise offensive, vulgar or obscene.
                        </p>

                        <h4 className='blue-heading'>Content</h4>

                        <h4>Your Right to Post Content </h4>

                        <p>
                            Our Service allows You to post Content. You are responsible for
                            the Content that You post to the Service, including its legality,
                            reliability, and appropriateness.
                        </p>
                        <p>
                            By posting Content to the Service, You grant Us the right and
                            license to use, modify, publicly perform, publicly display,
                            reproduce, and distribute such Content on and through the Service.
                            You retain any and all of Your rights to any Content You submit,
                            post or display on or through the Service and You are responsible
                            for protecting those rights. You agree that this license includes
                            the right for Us to make Your Content available to other users of
                            the Service, who may also use Your Content subject to these Terms.
                        </p>
                        <p>
                            You represent and warrant that: (i) the Content is Yours (You own
                            it) or You have the right to use it and grant Us the rights and
                            license as provided in these Terms, and (ii) the posting of Your
                            Content on or through the Service does not violate the privacy
                            rights, publicity rights, copyrights, contract rights or any other
                            rights of any person.
                        </p>

                        <h4>Content Restrictions </h4>
                        <p>
                            The Company is not responsible for the content of the Service's
                            users. You expressly understand and agree that You are solely
                            responsible for the Content and for all activity that occurs under
                            your account, whether done so by You or any third person using
                            Your account.
                        </p>
                        <p>
                            You may not transmit any Content that is unlawful, offensive,
                            upsetting, intended to disgust, threatening, libelous, defamatory,
                            obscene or otherwise objectionable. Examples of such objectionable
                            Content include, but are not limited to, the following:
                        </p>
                        <p>
                            <ul>
                                <li>- Unlawful or promoting unlawful activity.</li>
                                <li>
                                    - Defamatory, discriminatory, or mean-spirited content,
                                    including references or commentary about religion, race,
                                    sexual orientation, gender, national/ethnic origin, or other
                                    targeted groups.
                                </li>
                                <li>
                                    - Spam, machine – or randomly – generated, constituting
                                    unauthorized or unsolicited advertising, chain letters, any
                                    other form of unauthorized solicitation, or any form of
                                    lottery or gambling.
                                </li>
                                <li>
                                    - Containing or installing any viruses, worms, malware, trojan
                                    horses, or other content that is designed or intended to
                                    disrupt, damage, or limit the functioning of any software,
                                    hardware or telecommunications equipment or to damage or
                                    obtain unauthorized access to any data or other information of
                                    a third person.
                                </li>
                                <li>
                                    - Infringing on any proprietary rights of any party, including
                                    patent, trademark, trade secret, copyright, right of publicity
                                    or other rights.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Impersonating any person or entity including the Company and its
                            employees or representatives.
                        </p>
                        <p>
                            <ul>
                                <li>- Violating the privacy of any third person.</li>
                                <li>- False information and features.</li>
                            </ul>
                        </p>
                        <p>
                            The Company reserves the right, but not the obligation, to, in its
                            sole discretion, determine whether or not any Content is
                            appropriate and complies with this Terms, refuse or remove this
                            Content. The Company further reserves the right to make formatting
                            and edits and change the manner any Content. The Company can also
                            limit or revoke the use of the Service if You post such
                            objectionable Content.
                        </p>
                        <p>
                            As the Company cannot control all content posted by users and/or
                            third parties on the Service, you agree to use the Service at your
                            own risk. You understand that by using the Service You may be
                            exposed to content that You may find offensive, indecent,
                            incorrect or objectionable, and You agree that under no
                            circumstances will the Company be liable in any way for any
                            content, including any errors or omissions in any content, or any
                            loss or damage of any kind incurred as a result of your use of any
                            content.
                        </p>

                        <h4>Content Backups</h4>
                        <p>
                            Although regular backups of Content are performed, the Company do
                            not guarantee there will be no loss or corruption of data.
                        </p>
                        <p>
                            Corrupt or invalid backup points may be caused by, without
                            limitation, Content that is corrupted prior to being backed up or
                            that changes during the time a backup is performed.
                        </p>
                        <p>
                            The Company will provide support and attempt to troubleshoot any
                            known or discovered issues that may affect the backups of Content.
                            But You acknowledge that the Company has no liability related to
                            the integrity of Content or the failure to successfully restore
                            Content to a usable state.
                        </p>
                        <p>
                            You agree to maintain a complete and accurate copy of any Content
                            in a location independent of the Service.
                        </p>

                        <h4 className='blue-heading'>Copyright Policy </h4>

                        <h4>Intellectual Property Infringement </h4>
                        <p>
                            We respect the intellectual property rights of others. It is Our
                            policy to respond to any claim that Content posted on the Service
                            infringes a copyright or other intellectual property infringement
                            of any person.
                        </p>
                        <p>
                            If You are a copyright owner, or authorized on behalf of one, and
                            You believe that the copyrighted work has been copied in a way
                            that constitutes copyright infringement that is taking place
                            through the Service, You must submit Your notice in writing to the
                            attention of our copyright agent via email at hello@fendrbendr.com
                            and include in Your notice a detailed description of the alleged
                            infringement.
                        </p>
                        <p>
                            You may be held accountable for damages (including costs and
                            attorneys' fees) for misrepresenting that any Content is
                            infringing Your copyright.
                        </p>

                        <h4>
                            DMCA Notice and DMCA Procedure for Copyright Infringement Claims
                        </h4>
                        <p>
                            You may submit a notification pursuant to the Digital Millennium
                            Copyright Act (DMCA) by providing our Copyright Agent with the
                            following information in writing (see 17 U.S.C 512(c)(3) for
                            further detail):
                        </p>
                        <p>
                            <ul>
                                <li>
                                    - An electronic or physical signature of the person authorized
                                    to act on behalf of the owner of the copyright's interest.
                                </li>
                                <li>
                                    - A description of the copyrighted work that You claim has
                                    been infringed, including the URL (i.e., web page address) of
                                    the location where the copyrighted work exists or a copy of
                                    the copyrighted work.
                                </li>
                                <li>
                                    Identification of the URL or other specific location on the
                                    Service where the material that You claim is infringing is
                                    located.
                                </li>
                                <li>- Your address, telephone number, and email address.</li>
                                <li>
                                    - A statement by You that You have a good faith belief that
                                    the disputed use is not authorized by the copyright owner, its
                                    agent, or the law.
                                </li>
                                <li>
                                    - A statement by You, made under penalty of perjury, that the
                                    above information in Your notice is accurate and that You are
                                    the copyright owner or authorized to act on the copyright
                                    owner's behalf.
                                </li>
                            </ul>
                        </p>
                        <p>
                            You can contact our copyright agent via email at
                            hello@fendrbendr.com. Upon receipt of a notification, the Company
                            will take whatever action, in its sole discretion, it deems
                            appropriate, including removal of the challenged content from the
                            Service.
                        </p>

                        <h4>Intellectual Property </h4>
                        <p>
                            The Service and its original content (excluding Content provided
                            by You or other users), features and functionality are and will
                            remain the exclusive property of the Company and its licensors.
                        </p>
                        <p>
                            The Service is protected by copyright, trademark, and other laws
                            of both the Country and foreign countries.
                        </p>
                        <p>
                            Our trademarks and trade dress may not be used in connection with
                            any product or service without the prior written consent of the
                            Company.
                        </p>

                        <h4>Your Feedback to Us</h4>
                        <p>
                            You assign all rights, title and interest in any Feedback You
                            provide the Company. If for any reason such assignment is
                            ineffective, You agree to grant the Company a non-exclusive,
                            perpetual, irrevocable, royalty free, worldwide right and licence
                            to use, reproduce, disclose, sub-licence, distribute, modify and
                            exploit such Feedback without restriction.
                        </p>

                        <h4>Links to Other Websites </h4>
                        <p>
                            Our Service may contain links to third-party web sites or services
                            that are not owned or controlled by the Company.
                        </p>
                        <p>
                            The Company has no control over, and assumes no responsibility
                            for, the content, privacy policies, or practices of any third
                            party web sites or services. You further acknowledge and agree
                            that the Company shall not be responsible or liable, directly or
                            indirectly, for any damage or loss caused or alleged to be caused
                            by or in connection with the use of or reliance on any such
                            content, goods or services available on or through any such web
                            sites or services.
                        </p>
                        <p>
                            We strongly advise You to read the terms and conditions and
                            privacy policies of any third-party web sites or services that You
                            visit.
                        </p>

                        <h4>Termination </h4>
                        <p>
                            We may terminate or suspend Your Account immediately, without
                            prior notice or liability, for any reason whatsoever, including
                            without limitation if You breach these
                        </p>

                        <h4>Terms and Conditions.</h4>
                        <p>
                            Upon termination, Your right to use the Service will cease
                            immediately. If You wish to terminate Your Account, You may simply
                            discontinue using the Service.
                        </p>

                        <h4>Limitation of Liability</h4>
                        <p>
                            Notwithstanding any damages that You might incur, the entire
                            liability of the Company and any of its suppliers under any
                            provision of this Terms and Your exclusive remedy for all of the
                            foregoing shall be limited to the amount actually paid by You
                            through the Service or 100 USD if You haven't purchased anything
                            through the Service.
                        </p>
                        <p>
                            To the maximum extent permitted by applicable law, in no event
                            shall the Company or its suppliers be liable for any special,
                            incidental, indirect, or consequential damages whatsoever
                            (including, but not limited to, damages for loss of profits, loss
                            of data or other information, for business interruption, for
                            personal injury, loss of privacy arising out of or in any way
                            related to the use of or inability to use the Service, third-party
                            software and/or third-party hardware used with the Service, or
                            otherwise in connection with any provision of this Terms), even if
                            the Company or any supplier has been advised of the possibility of
                            such damages and even if the remedy fails of its essential
                            purpose.
                        </p>
                        <p>
                            Some states do not allow the exclusion of implied warranties or
                            limitation of liability for incidental or consequential damages,
                            which means that some of the above limitations may not apply. In
                            these states, each party's liability will be limited to the
                            greatest extent permitted by law.
                        </p>

                        <h4>"AS IS" and "AS AVAILABLE" Disclaimer </h4>
                        <p>
                            The Service is provided to You "AS IS" and "AS AVAILABLE" and with
                            all faults and defects without warranty of any kind. To the
                            maximum extent permitted under applicable law, the Company, on its
                            own behalf and on behalf of its Affiliates and its and their
                            respective licensors and service providers, expressly disclaims
                            all warranties, whether express, implied, statutory or otherwise,
                            with respect to the Service, including all implied warranties of
                            merchantability, fitness for a particular purpose, title and
                            non-infringement, and warranties that may arise out of course of
                            dealing, course of performance, usage or trade practice. Without
                            limitation to the foregoing, the Company provides no warranty or
                            undertaking, and makes no representation of any kind that the
                            Service will meet Your requirements, achieve any intended results,
                            be compatible or work with any other software, applications,
                            systems or services, operate without interruption, meet any
                            performance or reliability standards or be error free or that any
                            errors or defects can or will be corrected.
                        </p>
                        <p>
                            Without limiting the foregoing, neither the Company nor any of the
                            company's provider makes any representation or warranty of any
                            kind, express or implied: (i) as to the operation or availability
                            of the Service, or the information, content, and materials or
                            products included thereon; (ii) that the Service will be
                            uninterrupted or error-free; (iii) as to the accuracy,
                            reliability, or currency of any information or content provided
                            through the Service; or (iv) that the Service, its servers, the
                            content, or e-mails sent from or on behalf of the Company are free
                            of viruses, scripts, trojan horses, worms, malware, timebombs or
                            other harmful components.
                        </p>
                        <p>
                            Some jurisdictions do not allow the exclusion of certain types of
                            warranties or limitations on applicable statutory rights of a
                            consumer, so some or all of the above exclusions and limitations
                            may not apply to You. But in such a case the exclusions and
                            limitations set forth in this section shall be applied to the
                            greatest extent enforceable under applicable law.
                        </p>

                        <h4>Governing Law</h4>
                        <p>
                            The laws of the Country, excluding its conflicts of law rules,
                            shall govern this Terms and Your use of the Service. Your use of
                            the Application may also be subject to other local, state,
                            national, or international laws.
                        </p>

                        <h4>Disputes Resolution </h4>
                        <p>
                            If You have any concern or dispute about the Service, You agree to
                            first try to resolve the dispute informally by contacting the
                            Company.
                        </p>

                        <h4>For European Union (EU) Users </h4>
                        <p>
                            If You are a European Union consumer, you will benefit from any
                            mandatory provisions of the law of the country in which you are
                            resident in.
                        </p>

                        <h4>United States Legal Compliance</h4>
                        <p>
                            You represent and warrant that (i) You are not located in a
                            country that is subject to the United States government embargo,
                            or that has been designated by the United States government as a
                            “terrorist supporting” country, and (ii) You are not listed on any
                            United States government list of prohibited or restricted parties.
                        </p>

                        <h4 className='blue-heading'>Severability and Waiver</h4>

                        <h4>Severability</h4>
                        <p>
                            If any provision of these Terms is held to be unenforceable or
                            invalid, such provision will be changed and interpreted to
                            accomplish the objectives of such provision to the greatest extent
                            possible under applicable law and the remaining provisions will
                            continue in full force and effect.
                        </p>

                        <h4>Waiver</h4>
                        <p>
                            Except as provided herein, the failure to exercise a right or to
                            require performance of an obligation under this Terms shall not
                            effect a party's ability to exercise such right or require such
                            performance at any time thereafter nor shall be the waiver of a
                            breach constitute a waiver of any subsequent breach.
                        </p>
                        <p>Translation Interpretation</p>
                        <p>
                            These Terms and Conditions may have been translated if We have
                            made them available to You on our Service.
                        </p>
                        <p>
                            You agree that the original English text shall prevail in the case
                            of a dispute.
                        </p>

                        <h4>Changes to These Terms and Conditions </h4>
                        <p>
                            We reserve the right, at Our sole discretion, to modify or replace
                            these Terms at any time. If a revision is material We will make
                            reasonable efforts to provide at least 30 days' notice prior to
                            any new terms taking effect. What constitutes a material change
                            will be determined at Our sole discretion.
                        </p>
                        <p>
                            By continuing to access or use Our Service after those revisions
                            become effective, You agree to be bound by the revised terms. If
                            You do not agree to the new terms, in whole or in part, please
                            stop using the website and the Service.
                        </p>

                        <h4>Contact Us </h4>
                        <p>
                            If you have any questions about these Terms and Conditions, You
                            can contact us: By email:{' '}
                            <a href='mailto:hello@fendrbendr.com'>hello@fendrbendr.com</a>
                        </p>
                    </section>

                    <section className={`${!showTerms ? 'active' : ''}`}>
                        <p>
                            Fendr Bendr, Inc. (Fendr Bendr) owns and operates the website
                            <a href="//www.fendrbendr.com" target="_blank"> www.fendrbendr.com</a> (the Site). Fendr
                            Bendr also develops, operates and distributes certain software applications (Apps), which
                            include hosted applications and applications for mobile devices. The Site and the Apps,
                            together with the referral, matching and appointment services, and the data collection,
                            storage, analysis and reporting tools, functions and services, that are provided at the Site
                            and/or via the Apps, are collectively referred to as the Service.
                            The following policy explains how Fendr Bendr collects, uses and disseminates information
                            obtained from or about you as a user of the Service. Capitalized terms used herein without
                            definition will have the same meanings as defined in the Terms of Service applicable to the
                            Service, which are available at <a href="//www.fendrbendr.com/terms-conditions"
                                                               target="_blank"> www.fendrbendr.com/terms-conditions</a>
                        </p>
                        <h4 className='blue-heading no-margin'>
                            Information Collected
                        </h4>

                        <p>
                            When you visit the Site or otherwise access the Service, web servers collect general
                            information about your visit (such as, for example, traffic data like time, date and the
                            address of the website from which you entered the Site), which is stored as anonymous,
                            aggregate data. Fendr Bendr also collects more specific information about you and your use
                            of the Service (such as, for example, demographic data, profile data and frequency or
                            duration of use). Collecting such data (collectively, Traffic Data) may entail the use of
                            software programs, web beacons, pixel tags, Cookies (as defined below), IP addresses or
                            other numeric codes used to identify a computer.
                        </p>
                        <br/>
                        <p>
                            The Service may be accessed several ways: by visiting the Site directly; or by using an App
                            that is downloaded from Fendr Bendr (or a Channel Partner), which then communicates with
                            Fendr Bendr’s web servers; or by using a widget that is made available to you by a Channel
                            Partner, which operates or otherwise connects to Fendr Bendr web servers (and which may
                            operate within a frame or wrapper provided by the Channel Partner).
                            Regardless of how you access the Service, to ensure the integrity of the Service, you must
                            complete an account registration process and provide Fendr Bendr with current, complete and
                            accurate information, as more specifically required by then current registration procedures
                            and operational prompts.
                        </p>
                            <br/>
                        <p>
                            By way of illustration and not limitation, you may be required to provide the following
                            information in order to establish your account and register for and use the Service: name,
                            address, telephone number, e-mail address, vehicle information (make, model, year, mileage
                            and VIN), username, password and certain preferences and permissions (such as, for example,
                            preferred contact method).
                        </p>
                                <br/>
                        <p>
                            Whenever you interact with the Service, a variety of technologies are employed to
                            automatically or passively collect User Data. The term User Data means all data and
                            information that are provided by you during registration and subsequent use of the Service
                            (including queries related to obtaining Vehicle Services), and all other data and
                            information about you that are otherwise discerned or collected by Fendr Bendr based on your
                            access and use of the Service. For clarity, User Data includes, but is not limited to:
                            browser or device type; operating system; time of day; identification of the page served and
                            preceding page views; use of particular Service features or applications (such as, for
                            example, viewing or responding to advertisements); registration data; vehicle information;
                            geographic location; Vehicle Service histories. At no time will an App access, collect or
                            store any of the personal contacts or photographs or any other information stored on your
                            device without your permission.
                        </p>
                                    <br/>
                        <p>
                            If you elect to accept an offer for Vehicle Services and book an appointment with a
                            Mechanic, then you will be required to provide valid Credit Card Information (cardholder
                            name, card number, expiration date and security code), which will be verified by Fendr
                            Bendr’s service provider (merchant bank) before your appointment is confirmed.
                            Any User Content that you upload, post, submit or otherwise distribute to the Site, or via
                            any App or other aspect of the Service, will be available for public consumption.
                        </p>
                        <br/>

                        <h4 className='blue-heading no-margin'>
                            Children
                        </h4>
                        <p>
                            Fendr Bendr will not collect personally identifiable information from any person who is
                            actually known to us to be under the age of 13. If we become aware that a person under 13
                            has provided personally identifiable information, Fendr Bendr will take steps to remove such
                            information and terminate that individual’s account, access and use of the Service.
                        </p>
                        <br/>

                        <h4 className='blue-heading no-margin'>
                            Use of Information
                        </h4>
                        <p>
                            Except as set forth below, at no time will any or your personally identifiable information
                            be shared with any third party by Fendr Bendr. Your IP address is not linked to personally
                            identifiable information, but is used to gather broad demographic data and to monitor
                            statistics to improve the Service.
                        </p>
                        <br/>
                        <p>
                            Fendr Bendr uses Traffic Data to help diagnose problems with its web servers, to administer
                            the Service and to analyze user trends and behaviors. Fendr Bendr will share Traffic Data
                            with certain third parties for the purposes of technical and customer support.
                        </p>
                        <br/>
                        <p>
                            Fendr Bendr uses your registration data to contact you with information about benefits or
                            privileges available through the Service, to respond to your inquiries for additional
                            information (including to gather offers from Mechanics to provide Vehicle Services), to
                            provide additional information according to your preferences and for Fendr Bendr other
                            business purposes (collectively, Periodic Communications). At your election at any time, you
                            may choose to stop receiving some or all Periodic Communications from Fendr Bendr as
                            described below in the Opt-Out section.
                        </p>
                        <br/>
                        <p>
                            Fendr Bendr collects, stores, processes and analyzes User Data and produces Analytics. The
                            term Analytics means user profiles and statistics, metrics, abstractions and other analyses
                            that are based on or derived from your use of the Service and User Data, which are developed
                            in the aggregate with other data or which are developed in a manner that does not disclose
                            your identity or User Data (unless in aggregated or anonymized form).
                        </p>
                        <br/>
                        <p>
                            Fendr Bendr will use your User Data and exploit Analytics in the manner and for the purposes
                            described in the Terms of Service, including without limitation: to use User Data to
                            communicate with you; to include, copy, disclose, distribute, transmit and display User Data
                            in communications that you have initiated via the Service; to access, record, collect, copy,
                            store, process, analyze and use User Data to provide the Service (including to solicit
                            offers for Vehicle Services from Mechanics and to confirm appointments); to develop,
                            improve, extend and test the Service (and underlying technology platform); to design,
                            develop and produce Analytics; and to disclose, distribute and transmit User Data and/or
                            Analytics to Channel Partners, Mechanics, advertisers, advertising networks, content
                            distributors and other of Fendr Bendr customers, service providers and business partners
                            (collectively, Business Partners).
                        </p>
                        <br/>
                        <p>
                            Notwithstanding the foregoing, Fendr Bendr does not record or store your Credit Card
                            Information. Credit Card Information is provided to and retained by Fendr Bendr merchant
                            bank, where it is used solely for the purpose of verification and validation, and for
                            charging payments for completed Vehicle Services.
                        </p>
                        <br/>
                        <p>
                            User Content is intended to and will be made available to and used by other users, and by
                            Fendr Bendr and its Business Partners. Fendr Bendr will use your User Content in the manner
                            and for the purposes described in the Terms of Service, including without limitation: to
                            use, reproduce, publicly display, publicly perform, adapt, collect, modify, delete from,
                            distribute, transmit, promote and make derivative works of the User Content, in any form,
                            solely for the purposes of offering, providing, marketing, advertising and promoting the
                            Site, Apps and other aspects of the Service (including without limitation, being exported
                            under content sharing arrangements with Business Partners); and to permit third parties to
                            use your User Content.
                        </p>
                        <br/>

                        <h4 className='blue-heading no-margin'>
                            IP Addresses and Cookies
                        </h4>
                        <p>
                            Fendr Bendr uses your IP address to help diagnose problems with its web servers and
                            administer the Service. Your IP address is used to help identify you and to gather
                            demographic information. IP addresses are also used to provide an audit trail regarding use
                            of the Service.
                        </p>
                        <br/>
                        <p>
                            "Cookies" are pieces of information that a website transfers to your computer's hard disk
                            for record-keeping purposes. Cookies in and of themselves do not personally identify users,
                            although they do identify a user's computer. If applicable, you may be able to set your web
                            browser to refuse Cookies, which in turn may affect your ability to access and use the
                            Service.
                        </p>
                        <br/>
                        <p>
                            Third parties who provide advertising using the Service may also use Cookies for the purpose
                            of collecting and storing information. Fendr Bendr does not have access to or control over
                            third party advertisers’ use of Cookies. You may be able to opt-out of some of these
                            practices through the Network Advertising Initiative <a href="//www.networkadvertising.org"
                                                                                    target="_blank">
                            (www.networkadvertising.org)</a> and/or
                            PrivacyChoice <a href="//www.privacychoice.org"
                                             target="_blank"> (www.privacychoice.org)</a>.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Sharing of Personally Identifiable Information
                        </h4>
                        <p>
                            Currently, Fendr Bendr will not share personally identifiable information with any third
                            party for commercial purposes that are not related to the Service or its arrangements with
                            Channel Partners and other Business Partners. Fendr Bendr may, however, disclose personally
                            identifiable data if (a) authorized by you, (b) reasonably necessary for Fendr Bendr (or its
                            service providers) to operate the Service, (c) otherwise permitted under this Privacy Policy
                            or other Terms of Service or (d) Fendr Bendr reasonably believes that such action is
                            necessary (i) to conform or comply with any legal, regulatory, law enforcement or similar
                            requirement or investigation, (ii) to protect or defend the rights or property of Fendr
                            Bendr or any third party or (iii) to enforce the Terms of Service.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Business Transfers
                        </h4>
                        <p>
                            Fendr Bendr may transfer Traffic Data, User Data (including personally identifiable
                            information), User Content and Analytics to any successor to all or substantially all of its
                            business or assets that concerns the Service. In the event of such a transfer, such
                            successor will be having all of the rights and be subject to all of the obligations of this
                            Privacy Policy, including, without limitation, the right to modify or replace this Privacy
                            Policy, as provided herein.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Security
                        </h4>
                        <p>
                            Information collected by Fendr Bendr is stored in operating environments that are secured
                            using commercially available tools and procedures, and which are not made generally
                            available to the public. Unfortunately, no data transmission over the Internet and/or
                            wireless networks can be guaranteed to be 100% secure. As a result, Fendr Bendr cannot
                            ensure the security of any information you provide, and you do so at your own risk. Once
                            Fendr Bendr receives your information, it will make reasonable efforts to ensure its
                            security on Fendr Bendr systems.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Connections
                        </h4>
                        <p>
                            You may be able to obtain an App, access the Service and/or communicate with the Service
                            from, and you may be able to link or communicate from the Service to, applications, devices
                            and websites owned and operated by Mechanics, Channel Partners, advertisers or other
                            Business Partners. These other applications, devices and websites are not operated or
                            controlled by Fendr Bendr. Additional or different terms and conditions (including without
                            limitation, privacy and security practices) apply when you access and use such other
                            applications, devices and websites, which are not the responsibility of Fendr Bendr.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Opt-Out
                        </h4>
                        <p>
                            At any time, you may elect to opt-out from receiving Periodic Communications from Fendr
                            Bendr by sending an email to opt-out@fendrbendr.com; provided, Fendr Bendr reserves the
                            right to continue using your contact information to send you important information (such as,
                            for example, information about changes to this Privacy Policy, legal information, or account
                            information). Opting-out does not terminate your registration or account, and does not
                            terminate our right to continue serving advertisements while you use the Service.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Termination
                        </h4>
                        <p>
                            If you terminate your registration and account for the Service, or if your registration or
                            account is terminated for any reason by Fendr Bendr, then Fendr Bendr will remove your
                            personally identifiable information from the Service; provided, Fendr Bendr will retain the
                            rights and licenses to retain and use your other User Data and User Content (which is not
                            personally identifiable) as provided in this Privacy Policy and other Terms of Service.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Non-US Users
                        </h4>
                        <p>
                            YOU EXPRESSLY CONSENT TO THE TRANSMISSION, COLLECTION, STORAGE, PROCESSING AND USE OF YOUR
                            PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY. YOUR PERSONAL INFORMATION MAY
                            BE COLLECTED, STORED, PROCESSED, USED AND TRANSMITTED WITHIN, FROM AND TO THE COUNTRY WHERE
                            IT WAS COLLECTED AND THE UNITED STATES. UNITED STATES LAWS REGARDING PROCESSING OF PERSONAL
                            INFORMATION MAY BE LESS STRINGENT THAN THE LAWS IN YOUR COUNTRY.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Updates
                        </h4>
                        <p>
                            Fendr Bendr reserves the right, at its sole discretion, to update, modify or replace this
                            Privacy Policy, in whole or in part, at any time. Fendr Bendr will use reasonable efforts to
                            notify you of any material change in advance of the effective date of any change. Change
                            notices may be communicated by postings at the Site, via the Apps, e-mail or otherwise. In
                            any case, you should periodically check this Privacy Policy for changes. Continued access or
                            use of the Service following any change to this Privacy Policy constitutes your acceptance
                            of those changes. The Terms of Service may not otherwise be amended, as they apply to you,
                            except by a written agreement executed by you and Fendr Bendr.
                        </p>
                        <br/>
                        <p>
                            Information collected by the Service is subject to the Privacy Policy in effect at the time
                            of use.
                        </p>
                        <br/>
                        <p>
                            You may correct or otherwise change your registration data by contacting Fendr Bendr, as
                            provided below.
                        </p>
                        <br/>
                        <h4 className='blue-heading no-margin'>
                            Contact
                        </h4>
                        <p>
                            If you have any questions regarding this Privacy Policy or your dealings with the Service,
                            please visit <a href="//www.fendrbendr.com/contactus" target="_blank"> www.fendrbendr.com/contactus </a>
                            or contact us at:
                        </p>
                        <br/>
                        <p>
                            Fendr Bendr, Inc.
                        </p>
                        <p>
                            Attn: Privacy Policy
                        </p>
                        <p>
                            79 Madison Ave, 8 Floor
                        </p>
                        <p>
                            New York, NY 10016 USA
                        </p>
                        <p>
                            Tel: <a href='tel:888-601-0399'>888-601-0399</a>
                        </p>
                        <p>
                            E-mail: <a href='mailto:hello@fendrbendr.com'>hello@fendrbendr.com</a>
                        </p>
                        <br/>
                    </section>
                </div>
            </main>
        </div>
        </>
    )
}

export default TermsAndConditions
