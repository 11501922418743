import React, {useState, useEffect} from 'react'
import classNames from 'classnames'
import Search from './Search'
import faqListData from './faqListData'
import FAQList from './FaqListComponent';
import ScrollToTop from '../ScrollToTop/index';
import './style.css'

const FAQ: React.FC = () => {
    const [questionsList, setQuestionsList] = useState<FaqList[]>(faqListData)
    const [searchText, setSearchText] = useState<string>('')

    useEffect(() => {
        const filteredList = [
            ...questionsList.filter((list) => {
                return list.question.includes(searchText)
            }),
        ]

        setQuestionsList(filteredList)
        if (!searchText) setQuestionsList(faqListData)

        // eslint-disable-next-line
    }, [searchText])

    interface FaqList {
        question: String
        answer: String
    }
    return (
        <>
        <ScrollToTop/>
        <section className='section-light-gray faq-top-section'>
            <div className='narrow-container'>
                <div className='small-gray-heading'>Frequently Asked Questions</div>
                <h1>How can we help you?</h1>

                <Search onSearchChange={setSearchText}/>
            </div>
        </section>

        <section className='faq-list-section'>
            <div className='narrow-container'>
                <FAQList
                    list={questionsList}
                    classes={classNames('questions-answers', {
                        'hidden-description': searchText.length > 0,
                    })}
                />
            </div>
        </section>
        </>
    )
}

export default FAQ
