import React from 'react';
import ContactUsForm from '../common/forms/ContactUsForm';
import ScrollToTop from '../ScrollToTop/index';
import './style.css';

const ContactUs = () => {
    return (
        <>
        <ScrollToTop/>
        <section className='contact-us-section'>
            <div className='narrow-container'>
                <div className='form-description'>
                    <div className='small-gray-heading'>Contact Us</div>
                    <h1>Drop us a Message</h1>
                    <p>
                        Send us a message by filling out the form and will get back to you shortly.
                    </p>
                    <p>
                        You can also reach out to us by email at <a href="mailto:hello@fendrbendr.com">hello@fendrbendr.com</a>
                    </p>
                </div>

                <ContactUsForm />
            </div>
        </section>
        </>
    );
};

export default ContactUs;
