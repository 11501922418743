import React, {useEffect} from 'react';
import {VIDYARD_HOMEPAGE_VIDEO_UUID} from '../../config/keys';
import VidyardEmbed from "@vidyard/embed-code";
import './style.css';

const VidyardPlayer = () => {
    useEffect(() => {
        VidyardEmbed.api.renderPlayer({
            uuid: VIDYARD_HOMEPAGE_VIDEO_UUID,
            container: document.getElementById("video-container"),
            type: "lightbox",
            aspect: "landcape"
        });
    }, []);

    return (
        <div id="video-container"/>
    );
};

export default VidyardPlayer;
