import React from 'react';

const IconLock = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 6C14.9468 6 15.7143 6.76751 15.7143 7.71429V16.2857C15.7143 17.2325 14.9468 18 14 18H3.71429C2.76751 18 2 17.2325 2 16.2857V7.71429C2 6.76286 2.77143 6 3.71429 6H4.57143V4.28571C4.57143 1.91878 6.49021 0 8.85714 0C9.99378 0 11.0839 0.451529 11.8876 1.25526C12.6913 2.05898 13.1429 3.14907 13.1429 4.28571V6H14ZM8.85714 13.7143C9.80392 13.7143 10.5714 12.9468 10.5714 12C10.5714 11.0486 9.8 10.2857 8.85714 10.2857C7.91037 10.2857 7.14286 11.0532 7.14286 12C7.14286 12.9468 7.91037 13.7143 8.85714 13.7143ZM6.28571 4.28571C6.28571 2.86555 7.43698 1.71429 8.85714 1.71429C10.2773 1.71429 11.4286 2.86555 11.4286 4.28571V6H6.28571V4.28571Z'
        fill='#E2E1F1'
      />
      <mask
        id='IconLockMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='0'
        width='14'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 6C14.9468 6 15.7143 6.76751 15.7143 7.71429V16.2857C15.7143 17.2325 14.9468 18 14 18H3.71429C2.76751 18 2 17.2325 2 16.2857V7.71429C2 6.76286 2.77143 6 3.71429 6H4.57143V4.28571C4.57143 1.91878 6.49021 0 8.85714 0C9.99378 0 11.0839 0.451529 11.8876 1.25526C12.6913 2.05898 13.1429 3.14907 13.1429 4.28571V6H14ZM8.85714 13.7143C9.80392 13.7143 10.5714 12.9468 10.5714 12C10.5714 11.0486 9.8 10.2857 8.85714 10.2857C7.91037 10.2857 7.14286 11.0532 7.14286 12C7.14286 12.9468 7.91037 13.7143 8.85714 13.7143ZM6.28571 4.28571C6.28571 2.86555 7.43698 1.71429 8.85714 1.71429C10.2773 1.71429 11.4286 2.86555 11.4286 4.28571V6H6.28571V4.28571Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconLockMask)'>
        <rect width='18' height='18' fill='#E2E1F1' />
      </g>
    </svg>
  );
};

export default IconLock;
