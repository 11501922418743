import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

const LoadingSpinner = () => {
  const { promiseInProgress } = usePromiseTracker()

  return (
    <div>
      {promiseInProgress === true ? (
        <div className='loading-spinner'>
          <div className='loading-spinner__spin'></div>
        </div>
      ) : null}
    </div>
  )
}

export default LoadingSpinner
