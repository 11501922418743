import React, { useEffect } from 'react'
import { Switch, Redirect } from 'react-router'
import { sessionLogout } from '../../../api/session';

const Logout = ({ setAuthorized }) => {
  useEffect(() => {
    setAuthorized(false)
    sessionLogout()
  })

  return (
    <Switch>
      <Redirect exact from='/logout' to='/login' />
    </Switch>
  )
}

export default Logout
