import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import VidyardPlayer from './VidyardPlayer';
import './style.css';

const HowItWorksVideoDemo = ({placeholder}) => {
    return (
        <div
            className={classNames('video-container', {placeholder: placeholder})}
        >
            {placeholder &&
            <div
                className={classNames('video-placeholder', {})}
            >
                <div className='narrow-container'>
                    <h1>How It Works</h1>
                    <p>Find a trusted partner and get quotes for your car.</p>
                </div>

            </div>
            }
            <VidyardPlayer/>

        </div>
    );
};

export default HowItWorksVideoDemo;
