import React from 'react';
import ContactUsForm from '../common/forms/ContactUsForm';
import ScrollToTop from '../ScrollToTop/index';
import './style.css';

const BecomePartner = () => {
    return (
        <>
        <ScrollToTop/>
        <section className='become-partner-section'>
            <div className='narrow-container'>
                <div className='form-description'>
                    <div className='small-gray-heading'>Let’s Team Up?</div>
                    <h1>Become a Partner </h1>
                    <p>
                        Thank you for the interest on becoming a partner. Send us a message by filling out the form and
                        will get back to you shortly.
                    </p>
                    <p>
                        You can also reach out to us by email at <a href="mailto:hello@fendrbendr.com">hello@fendrbendr.com</a>
                    </p>
                </div>

                <ContactUsForm />
            </div>
        </section>
        </>
    );
};

export default BecomePartner;
