import React, { useState } from 'react';
import SidebarButton from './SidebarButton';
import SidebarContent from './SidebarContent';
import './style.css';

const SidebarRight = ({ onChangeFixedLayout }) => {
  const [opened, setOpened] = useState(false);

  const onSetOpened = (value) => {
    setOpened(value);
    onChangeFixedLayout(value);
  };

  return (
    <>
      <SidebarContent setOpened={onSetOpened} opened={opened} />
      <SidebarButton setOpened={onSetOpened} opened={opened} />
    </>
  );
};

export default SidebarRight;
