export function setJwtToken(token) {
  localStorage.setItem('jwtToken', token)
}

export function getJwtToken() {
  return localStorage.getItem('jwtToken')
}

export function sessionLogout() {
  localStorage.clear();
}

export function isAuthorized() {
  return typeof getJwtToken() === 'string';
}
