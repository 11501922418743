export default [
  {
    date: 'July 16, 2019',
    vehicle: 'Audi SQ5 - 3.0 TFSI - 2016',
    repairNumber: '1837402820',
    averageCost: '$ 650.00',
    repairType: 'Exterior - Real Door',
  },
  {
    date: 'July 16, 2019',
    vehicle: 'Audi SQ5 - 3.0 TFSI - 2016',
    repairNumber: '1837402820',
    averageCost: '$ 650.00',
    repairType: 'Exterior - Real Door',
  },
  {
    date: 'July 16, 2019',
    vehicle: 'Audi SQ5 - 3.0 TFSI - 2016',
    repairNumber: '1837402820',
    averageCost: '$ 650.00',
    repairType: 'Exterior - Real Door',
  },
]
