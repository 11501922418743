import React from 'react';

const IconEngineWhite = () => {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M86.1467 50C86.1467 51.65 85.9967 53.25 85.7967 54.85L96.3466 63.15C97.2967 63.9 97.5466 65.25 96.9466 66.35L86.9467 83.65C86.3467 84.75 84.9967 85.15 83.8967 84.75L71.4467 79.7C68.8467 81.7 66.1467 83.35 62.9967 84.65L61.1467 97.9C60.9467 99.1 59.8967 100 58.6467 100H38.6467C37.3967 100 36.3467 99.1 36.1467 97.9L34.2967 84.65C31.1467 83.4 28.4467 81.7 25.8467 79.7L13.3967 84.75C12.2967 85.15 10.9467 84.75 10.3467 83.65L0.346658 66.35C-0.303342 65.25 -0.00334201 63.9 0.946658 63.15L11.4967 54.85C11.2967 53.25 11.1467 51.65 11.1467 50C11.1467 48.35 11.2967 46.7 11.4967 45L0.946658 36.85C-0.00334201 36.1 -0.303342 34.75 0.346658 33.65L10.3467 16.35C10.9467 15.25 12.2967 14.8 13.3967 15.25L25.8467 20.25C28.4467 18.3 31.1467 16.6 34.2967 15.35L36.1467 2.1C36.3467 0.9 37.3967 0 38.6467 0H58.6467C59.8967 0 60.9467 0.9 61.1467 2.1L62.9967 15.35C66.1467 16.6 68.8467 18.3 71.4467 20.25L83.8967 15.25C84.9967 14.8 86.3467 15.25 86.9467 16.35L96.9466 33.65C97.5466 34.75 97.2967 36.1 96.3466 36.85L85.7967 45C85.9967 46.7 86.1467 48.35 86.1467 50ZM31.1469 49.9999C31.1469 59.6649 38.9819 67.4999 48.6469 67.4999C58.3119 67.4999 66.1469 59.6649 66.1469 49.9999C66.1469 40.3349 58.3119 32.4999 48.6469 32.4999C38.9819 32.4999 31.1469 40.3349 31.1469 49.9999Z'
        fill='white'
      />
      <mask
        id='IconEngineWhiteMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='98'
        height='100'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M86.1467 50C86.1467 51.65 85.9967 53.25 85.7967 54.85L96.3466 63.15C97.2967 63.9 97.5466 65.25 96.9466 66.35L86.9467 83.65C86.3467 84.75 84.9967 85.15 83.8967 84.75L71.4467 79.7C68.8467 81.7 66.1467 83.35 62.9967 84.65L61.1467 97.9C60.9467 99.1 59.8967 100 58.6467 100H38.6467C37.3967 100 36.3467 99.1 36.1467 97.9L34.2967 84.65C31.1467 83.4 28.4467 81.7 25.8467 79.7L13.3967 84.75C12.2967 85.15 10.9467 84.75 10.3467 83.65L0.346658 66.35C-0.303342 65.25 -0.00334201 63.9 0.946658 63.15L11.4967 54.85C11.2967 53.25 11.1467 51.65 11.1467 50C11.1467 48.35 11.2967 46.7 11.4967 45L0.946658 36.85C-0.00334201 36.1 -0.303342 34.75 0.346658 33.65L10.3467 16.35C10.9467 15.25 12.2967 14.8 13.3967 15.25L25.8467 20.25C28.4467 18.3 31.1467 16.6 34.2967 15.35L36.1467 2.1C36.3467 0.9 37.3967 0 38.6467 0H58.6467C59.8967 0 60.9467 0.9 61.1467 2.1L62.9967 15.35C66.1467 16.6 68.8467 18.3 71.4467 20.25L83.8967 15.25C84.9967 14.8 86.3467 15.25 86.9467 16.35L96.9466 33.65C97.5466 34.75 97.2967 36.1 96.3466 36.85L85.7967 45C85.9967 46.7 86.1467 48.35 86.1467 50ZM31.1469 49.9999C31.1469 59.6649 38.9819 67.4999 48.6469 67.4999C58.3119 67.4999 66.1469 59.6649 66.1469 49.9999C66.1469 40.3349 58.3119 32.4999 48.6469 32.4999C38.9819 32.4999 31.1469 40.3349 31.1469 49.9999Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconEngineWhiteMask)'>
        <rect width='100' height='100' fill='white' />
      </g>
    </svg>
  );
};

export default IconEngineWhite;
