export default [
  {
    question: 'How can I get a repair?',
    answer:
      'After you provide us with the location, vehicle information, and a detailed description of the repair needed, the local repair shops around you will provide bids to repair your vehicle. You will then be able to chat personally with the repair shops and evaluate the offers. Once you select a repair shop, you can book an appointment and then release payment once the repair has been completed.',
  },
  {
    question: 'What types of repairs does Fendr Bendr offer?',
    answer:
      'Through FendrBendr, you will be able to receive quotes for any mechanical, engine, exterior, or interior damage to your car.',
  },
  {
    question: 'How is payment processed?',
    answer:
      'Once you select a repair shop bid, there will be a hold on your credit card for the repair cost payment. After the repair is completed, you will then be able to release the payment to the repair shop through our app.',
  },
  {
    question: 'What types of payments are accepted?',
    answer: 'We are only accepting credit cards payments at this time.',
  },
  {
    question: 'What kind of repair shops participate in Fendr Bendr?',
    answer:
      'Local repair shops who have a good reputation and who comply with all of their local and federal regulations.',
  },
  {
    question: 'How long does it take to get quotes from repair shops?',
    answer:
      'Once you post your repair request in the platform, local repair shops will be able to see it and provide accurate bids. On average, it will take less than 24 hours to receive quotes from repair shops near you.',
  },
  {
    question: 'How do I communicate with the repair shops I am considering?',
    answer:
      'After you submit your repair request, you will be able to chat with repair shops that submit bids and discuss any questions or concerns prior to selecting them for repair.',
  },
  {
    question:
      'What happens if the repair cost increases once I take it to the repair shop?',
    answer:
      'In case the repair cost increases when you go to the location, you will be able to cancel the repair or increase the cost amount when releasing the payment if you accept this increase.',
  },
  {
    question: 'What happens if I am not happy with the repair?',
    answer:
      'In case there are any issues with the repair, you are able to open a dispute using our dispute center prior to releasing the payment. After you provide details of the issue, we will contact the repair shop and help solve the issue.',
  },
  {
    question:
      'What are the requirements for repair shops to participate on FendrBendr?',
    answer:
      'After registering an account, a Fendr Bendr representative will reach out to the repair shop and confirm that the repair shop complies with all local and federal requirements prior to being able to participate in the platform.',
  },
  {
    question: 'How can I manage notifications?',
    answer:
      'You will be able to manage notifications in the “Settings” menu after registering for the platform.',
  },
  {
    question: 'How can I contact Fendr Bendr?',
    answer:
      'If you have any questions or concerns, feel free to visit our contact page to reach out to us.',
  },
]
