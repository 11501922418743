import React from 'react'

const IconEditLink = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 13.9706 4.02944 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0ZM9 1.8C12.9765 1.8 16.2 5.02355 16.2 9C16.2 12.9764 12.9765 16.2 9 16.2C7.09044 16.2 5.25909 15.4414 3.90883 14.0912C2.55857 12.7409 1.8 10.9096 1.8 9C1.8 5.02355 5.02355 1.8 9 1.8ZM7.65 9C7.65 8.25442 8.25442 7.65 9 7.65C9.74558 7.65 10.35 8.25442 10.35 9C10.35 9.74558 9.74558 10.35 9 10.35C8.25442 10.35 7.65 9.74558 7.65 9ZM13.05 7.65C12.3044 7.65 11.7 8.25442 11.7 9C11.7 9.74558 12.3044 10.35 13.05 10.35C13.7956 10.35 14.4 9.74558 14.4 9C14.4 8.25442 13.7956 7.65 13.05 7.65ZM3.6 9C3.6 8.25442 4.20442 7.65 4.95 7.65C5.69558 7.65 6.3 8.25442 6.3 9C6.3 9.74558 5.69558 10.35 4.95 10.35C4.20442 10.35 3.6 9.74558 3.6 9Z'
        fill='black'
      />
      <mask
        id='IconEditLinkMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 13.9706 4.02944 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0ZM9 1.8C12.9765 1.8 16.2 5.02355 16.2 9C16.2 12.9764 12.9765 16.2 9 16.2C7.09044 16.2 5.25909 15.4414 3.90883 14.0912C2.55857 12.7409 1.8 10.9096 1.8 9C1.8 5.02355 5.02355 1.8 9 1.8ZM7.65 9C7.65 8.25442 8.25442 7.65 9 7.65C9.74558 7.65 10.35 8.25442 10.35 9C10.35 9.74558 9.74558 10.35 9 10.35C8.25442 10.35 7.65 9.74558 7.65 9ZM13.05 7.65C12.3044 7.65 11.7 8.25442 11.7 9C11.7 9.74558 12.3044 10.35 13.05 10.35C13.7956 10.35 14.4 9.74558 14.4 9C14.4 8.25442 13.7956 7.65 13.05 7.65ZM3.6 9C3.6 8.25442 4.20442 7.65 4.95 7.65C5.69558 7.65 6.3 8.25442 6.3 9C6.3 9.74558 5.69558 10.35 4.95 10.35C4.20442 10.35 3.6 9.74558 3.6 9Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconEditLinkMask)'>
        <rect
          width='18'
          height='18'
          transform='matrix(-1 0 0 1 18 0)'
          fill='#554DF1'
        />
      </g>
    </svg>
  )
}

export default IconEditLink
