export default [
  {
    author: 'Michael Bronston',
    avatarUrl: '/img/ava-3.jpg',
    date: 'July 16, 2019',
    text:
      "“It's exactly what I've been looking for. Absolutely wonderful! I just can't get enough of Fendr Bendr“",
    repairType: 'Breaks',
  },
  {
    author: 'Michael Bronston',
    avatarUrl: '/img/ava-3.jpg',
    date: 'July 16, 2019',
    text:
      "“It's exactly what I've been looking for. Absolutely wonderful! I just can't get enough of Fendr Bendr“",
    repairType: 'Breaks',
  },
  {
    author: 'Michael Bronston',
    avatarUrl: '/img/ava-3.jpg',
    date: 'July 16, 2019',
    text:
      "“It's exactly what I've been looking for. Absolutely wonderful! I just can't get enough of Fendr Bendr“",
    repairType: 'Breaks',
  },
]
