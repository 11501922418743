import React from 'react';

const IconGooglePlus = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 9.87273H5.72727V7.90909H11.1273C11.2091 8.23636 11.2091 8.48182 11.2091 8.89091C11.2091 12.1636 9 14.4545 5.72727 14.4545C2.53636 14.4545 0 11.9182 0 8.72727C0 5.53636 2.53636 3 5.72727 3C7.28182 3 8.59091 3.57273 9.57273 4.55455L8.01818 6.02727C7.60909 5.61818 6.87273 5.12727 5.72727 5.12727C3.76364 5.12727 2.20909 6.76364 2.20909 8.72727C2.20909 10.6909 3.76364 12.3273 5.72727 12.3273C8.01818 12.3273 8.83636 10.6909 9 9.87273ZM16.3636 7.90909H18V9.54545H16.3636V11.1818H14.7273V9.54545H13.0909V7.90909H14.7273V6.27273H16.3636V7.90909Z'
        fill='black'
      />
      <mask
        id='IconGooglePlusMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='3'
        width='18'
        height='12'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9 9.87273H5.72727V7.90909H11.1273C11.2091 8.23636 11.2091 8.48182 11.2091 8.89091C11.2091 12.1636 9 14.4545 5.72727 14.4545C2.53636 14.4545 0 11.9182 0 8.72727C0 5.53636 2.53636 3 5.72727 3C7.28182 3 8.59091 3.57273 9.57273 4.55455L8.01818 6.02727C7.60909 5.61818 6.87273 5.12727 5.72727 5.12727C3.76364 5.12727 2.20909 6.76364 2.20909 8.72727C2.20909 10.6909 3.76364 12.3273 5.72727 12.3273C8.01818 12.3273 8.83636 10.6909 9 9.87273ZM16.3636 7.90909H18V9.54545H16.3636V11.1818H14.7273V9.54545H13.0909V7.90909H14.7273V6.27273H16.3636V7.90909Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconGooglePlusMask)'>
        <rect width='18' height='18' fill='#191D3A' />
      </g>
    </svg>
  );
};

export default IconGooglePlus;
