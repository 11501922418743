import React, { useState } from 'react'
import classNames from 'classnames'
import { Input, Form } from '@rocketseat/unform'
import { withRouter } from 'react-router-dom'
import IconMail from '../../../components/common/forms/ContactUsForm/svg-icons/IconMail'
import IconLock from '../../SignupContainer/svg-icons/IconLock'
import { initialDataSchema } from './formData'
import { Link } from 'react-router-dom'
import IconFacebook from '../../../components/Layout/svg-icons/IconFacebook'
import IconGooglePlus from '../../SignupContainer/svg-icons/IconGooglePlus'
import { trackPromise } from 'react-promise-tracker'
import * as Yup from 'yup'
import api from '../../../api'
import { setJwtToken } from '../../../api/session'

function LoginForm({ history, ...props }) {
  const [formData, setFormData] = useState(initialDataSchema)
  const [errors, setErrors] = useState({})
  const [serverError, setServerError] = useState('')

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(),
    password: Yup.string()
      .required()
      .min(6),
  })

  const resetForm = () => {
    setFormData(initialDataSchema)
  }

  const storeToForm = (changedData) => {
    setFormData({ ...formData, ...changedData })
  }

  const handleChange = (e) => {
    storeToForm({ [e.target.name]: e.target.value })
    // Cleanup fields and server error if it exists
    cleanFieldError(e.target.name)
    if (serverError !== '') {
      setServerError('')
    }
  }

  const fieldHasError = (fieldName) => {
    return (
      errors.inner && errors.inner.filter((i) => i.path === fieldName).length
    )
  }

  const getFieldError = (fieldName) => {
    let error
    if (
      errors.inner &&
      errors.inner.filter((i) => i.path === fieldName).length
    ) {
      error = errors.inner.filter((i) => i.path === fieldName)[0].message
    }
    return error
  }

  const cleanFieldError = (fieldName) => {
    if (errors.inner) {
      let updatedErrors = { ...errors }
      updatedErrors.inner = errors.inner.filter((i) => i.path !== fieldName)
      setErrors(updatedErrors)
    }
  }

  async function handleSubmit(formData) {
    // const dataForLogin = {
    //   email: 'nikitagromozdin@rambler.ru',
    //   password: 'string',
    // }

    try {
      // Remove all previous errors
      setErrors({})
      await formSchema.validate(formData, {
        abortEarly: false,
      })
      // Form validation passed in this time point, calling the API below

      // NOTE: trackPromise plugin wrapper here helps to store all
      // API calls promises in one place until they would be resolved
      // in order to easily handle global loading state
      // (helpful for showing global loading spinner in one place, etc...)
      trackPromise(
        api
          .login(formData)
          .then(async (res) => {
            await setJwtToken(res.data.data.token)
            props.setAuthorized(true)
            resetForm()
            history.push('/signup-business/welcome')
          })
          .catch((err) => {
            const error = JSON.parse(JSON.stringify(err)).message
            console.log(err)
            if (error === 'Request failed with status code 400') {
              setServerError('Please check email or password carefully')
            } else {
              setServerError(error)
            }
          })
      )
    } catch (err) {
      // Set all FE validation errors
      const validationErrors = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message
        })
        setErrors(err)
      }
    }
  }

  return (
    <div className='login-form-block'>
      <div className='top-switch-buttons'>
        <span className='button button-white bordered active'>Business</span>
        <span className='button button-white bordered disabled'>Customer</span>
      </div>

      <div className='heading'>
        <h3>Welcome Back!</h3>
        <p>Login to continue</p>
      </div>

      <Form onSubmit={handleSubmit} initialData={formData}>
        <div
          className={classNames('form-row', {
            'with-error': fieldHasError('email'),
          })}>
          <div className='form-field-with-icon'>
            <IconMail />
            <Input
              name='email'
              type='text'
              placeholder='Email'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-error'>{getFieldError('email')}</div>
        </div>
        <div
          className={classNames('form-row', {
            'with-error': fieldHasError('password'),
          })}>
          <div className='form-field-with-icon'>
            <IconLock />
            <Input
              name='password'
              type='password'
              placeholder='Password'
              className='input-field'
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className='form-error'>{getFieldError('password')}</div>
        </div>

        <div
          className={classNames('server-error', {
            'with-server-error': serverError.length,
          })}>
          {serverError}
        </div>

        <div className='controls-group'>
          <button type='submit' className='button button-blue'>
            Login
          </button>

          <Link
            to='/login/forgot-password'
            className='gray-link forgot-password-link'>
            Forgot password?
          </Link>
        </div>
      </Form>

      <div
        className='controls-group disabled'
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <label>Connect with</label>
        <div className='two-buttons'>
          <span className='button button-white button-transparent bordered'>
            <IconFacebook fillColor='#191d3a' />
          </span>
          <span className='button button-white button-transparent bordered'>
            <IconGooglePlus />
          </span>
        </div>
      </div>

      <div className='signup-suggestion'>
        Don’t have an account yet? <Link to='/signup'>Get Started</Link>
      </div>
    </div>
  )
}

export default withRouter(LoginForm)
