import React from 'react';

const IconZipCode = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.90909 1.63636L10.8182 3.36273V6.68455C11.2273 6.59455 11.6364 6.54545 12.0455 6.54545C13.4773 6.54545 14.77 7.11 15.7273 8.02636V0.409091C15.7273 0.183156 15.5441 0 15.3182 0H15.1873L10.8182 1.71818L5.90909 0L1.29455 1.55455C1.12273 1.61182 1 1.75909 1 1.94727V14.3182C1 14.5441 1.18316 14.7273 1.40909 14.7273L1.54 14.7027L5.90909 13.0091L6.94 13.3691C6.80091 12.8945 6.72727 12.3873 6.72727 11.8636V11.6509L5.90909 11.3645V1.63636ZM12.0455 8.18182C14.0909 8.18182 15.7273 9.81818 15.7273 11.8636C15.7273 12.5836 15.5227 13.2627 15.1627 13.8273L17.6827 16.3636L16.5455 17.5009L13.9927 14.9891C13.4282 15.3409 12.7573 15.5455 12.0455 15.5455C10 15.5455 8.36364 13.9091 8.36364 11.8636C8.36364 9.81818 10 8.18182 12.0455 8.18182ZM12.0455 9.81818C10.9158 9.81818 10 10.734 10 11.8636C10 12.9933 10.9158 13.9091 12.0455 13.9091C13.1751 13.9091 14.0909 12.9933 14.0909 11.8636C14.0909 10.734 13.1751 9.81818 12.0455 9.81818Z'
        fill='#554DF1'
      />
      <mask
        id='IconZipCodeMask'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width='17'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.90909 1.63636L10.8182 3.36273V6.68455C11.2273 6.59455 11.6364 6.54545 12.0455 6.54545C13.4773 6.54545 14.77 7.11 15.7273 8.02636V0.409091C15.7273 0.183156 15.5441 0 15.3182 0H15.1873L10.8182 1.71818L5.90909 0L1.29455 1.55455C1.12273 1.61182 1 1.75909 1 1.94727V14.3182C1 14.5441 1.18316 14.7273 1.40909 14.7273L1.54 14.7027L5.90909 13.0091L6.94 13.3691C6.80091 12.8945 6.72727 12.3873 6.72727 11.8636V11.6509L5.90909 11.3645V1.63636ZM12.0455 8.18182C14.0909 8.18182 15.7273 9.81818 15.7273 11.8636C15.7273 12.5836 15.5227 13.2627 15.1627 13.8273L17.6827 16.3636L16.5455 17.5009L13.9927 14.9891C13.4282 15.3409 12.7573 15.5455 12.0455 15.5455C10 15.5455 8.36364 13.9091 8.36364 11.8636C8.36364 9.81818 10 8.18182 12.0455 8.18182ZM12.0455 9.81818C10.9158 9.81818 10 10.734 10 11.8636C10 12.9933 10.9158 13.9091 12.0455 13.9091C13.1751 13.9091 14.0909 12.9933 14.0909 11.8636C14.0909 10.734 13.1751 9.81818 12.0455 9.81818Z'
          fill='white'
        />
      </mask>
      <g mask='url(#IconZipCodeMask)'>
        <rect width='18' height='18' fill='#554DF1' />
      </g>
    </svg>
  );
};

export default IconZipCode;
