import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import IconLinkArrow from '../../../../../components/svg-icons/IconLinkArrow'
import { Input } from '@rocketseat/unform'
import IconZipCode from '../../../svg-icons/IconZipCode'
import IconHome from '../../../svg-icons/IconHome'
import IconMapMarker from '../../../svg-icons/IconMapMarker'
import IconAddCircle from '../../../svg-icons/IconAddCircle'
import IconPhone from '../../../svg-icons/IconPhone'
import OpeningHours from './OpeningHours'
import AdditionalAddresses from './AdditionalAddresses'
import IconRemoveCircle from '../../../svg-icons/IconRemoveCircle'

const FormStep2 = ({
  setStep,
  step,
  storeToForm,
  workingHours,
  formData,
  errors,
  validate,
  setErrors,
}) => {
  const [currentErrors, setCurrentErrors] = useState(errors)

  const handleChange = (e) => {
    storeToForm({ [e.target.name]: e.target.value })

    // Cleanup fields and server error if it exists
    cleanFieldError(e.target.name)
  }

  const fieldHasError = (fieldName) => {
    return (
      currentErrors.inner &&
      currentErrors.inner.filter((i) => i.path === fieldName).length
    )
  }

  const getFieldError = (fieldName) => {
    let error
    if (
      currentErrors.inner &&
      currentErrors.inner.filter((i) => i.path === fieldName).length
    ) {
      error = currentErrors.inner.filter((i) => i.path === fieldName)[0].message
    }
    return error
  }

  const cleanFieldError = (fieldName) => {
    if (currentErrors.inner && currentErrors.inner.length) {
      let updatedErrors = { ...currentErrors }
      let indexOfRemovable
      updatedErrors.inner = currentErrors.inner.filter((inner, i) => {
        if (inner.path === fieldName) indexOfRemovable = i
        return inner.path !== fieldName
      })
      updatedErrors.errors = currentErrors.errors.filter((error, i) => {
        return i !== indexOfRemovable
      })
      setCurrentErrors(updatedErrors)
      setErrors(updatedErrors)
    }
  }

  const addNewAddress = (i) => {
    storeToForm(
      {
        additionalAddresses: [
          {
            zipCode: '',
            state: '',
            city: '',
            address: '',
            firstPhoneNumber: '',
            secondPhoneNumber: '',
          },
        ],
      },
      i
    )
  }

  const removeAddress = (index) => {
    storeToForm({
      additionalAddresses: formData.additionalAddresses.filter(
        (a, i) => i !== index
      ),
    })
  }

  const toNextStep = async () => {
    await validate()
  }

  useEffect(() => {
    // Setting errors only if they exist
    if (Object.keys(errors).length > 0) {
      setCurrentErrors(errors)
    }
  }, [errors])

  return (
    <>
      <div className='form-step-content form-step-2-content'>
        <div className='contact-info'>
          <h4>Contact Info</h4>

          <div className='addresses'>
            <div className='address'>
              <div
                className={classNames('form-row', {
                  'with-error': fieldHasError('zipCode'),
                })}>
                <div className='form-field-with-icon'>
                  <IconZipCode />
                  <Input
                    name='zipCode'
                    type='text'
                    placeholder='Zip Code'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>{getFieldError('zipCode')}</div>
                </div>
              </div>
              <div
                className={classNames('form-row duplet', {
                  'with-error': fieldHasError('state') || fieldHasError('city'),
                })}>
                <div className='form-field-with-icon'>
                  <IconMapMarker />
                  <Input
                    name='state'
                    type='text'
                    placeholder='State'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>{getFieldError('state')}</div>
                </div>
                <div className='form-field-with-icon'>
                  <IconMapMarker />
                  <Input
                    name='city'
                    type='text'
                    placeholder='City'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>{getFieldError('city')}</div>
                </div>
              </div>
              <div
                className={classNames('form-row', {
                  'with-error': fieldHasError('address'),
                })}>
                <div className='form-field-with-icon'>
                  <IconHome />
                  <Input
                    name='address'
                    type='text'
                    placeholder='Address'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>{getFieldError('address')}</div>
                </div>
              </div>

              <div
                className={classNames('form-row duplet', {
                  'with-error':
                    fieldHasError('firstPhoneNumber') ||
                    fieldHasError('secondPhoneNumber'),
                })}>
                <div className='form-field-with-icon'>
                  <IconPhone />
                  <Input
                    name='firstPhoneNumber'
                    type='tel'
                    placeholder='Phone Number 1'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>
                    {getFieldError('firstPhoneNumber')}
                  </div>
                </div>
                <div className='form-field-with-icon'>
                  <IconPhone />
                  <Input
                    name='secondPhoneNumber'
                    type='tel'
                    placeholder='Phone Number 2'
                    className='input-field'
                    onChange={(e) => handleChange(e)}
                  />
                  <div className='form-error'>
                    {getFieldError('secondPhoneNumber')}
                  </div>
                </div>
              </div>
            </div>

            <AdditionalAddresses
              addresses={formData.additionalAddresses}
              fieldHasError={fieldHasError}
              getFieldError={getFieldError}
              storeToForm={storeToForm}
              // currentErrors={currentErrors}
              // setCurrentErrors={setCurrentErrors}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {formData.additionalAddresses.length < 2 && (
                <span
                  className='add-link'
                  onClick={() =>
                    addNewAddress(formData.additionalAddresses.length)
                  }>
                  <IconAddCircle />
                  Add new address
                </span>
              )}
              {formData.additionalAddresses.length > 0 && (
                <span
                  className='add-link remove-link'
                  onClick={() =>
                    removeAddress(formData.additionalAddresses.length - 1)
                  }>
                  <IconRemoveCircle />
                  Remove address
                </span>
              )}
            </div>
          </div>
        </div>

        <div className='opening-hours'>
          <h4>Opening Hours</h4>
          <OpeningHours
            storeToForm={storeToForm}
            hoursCurrentState={workingHours}
          />
        </div>
      </div>

      <div className='form-steps-buttons'>
        <span
          className='button button-white bordered button-back'
          onClick={() => setStep(step - 1)}>
          <IconLinkArrow fillColor='black' />
          Back
        </span>

        <div className='controls-group'>
          <span className='button skip-link' onClick={() => setStep(step + 1)}>
            <IconLinkArrow fillColor='white' />
            Skip
          </span>
          <span className='button button-blue with-icon' onClick={toNextStep}>
            Next
            <IconLinkArrow fillColor='white' />
          </span>
        </div>
      </div>
    </>
  )
}

export default FormStep2
