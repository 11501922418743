export const formStepsNames = ['About', 'Information', 'Services']

export const initialDataSchema = {
  name: '',
  firstName: '',
  secondName: '',
  email: '',
  password: '',
  zipCode: '',
  state: '',
  city: '',
  address: '',
  firstPhoneNumber: '',
  secondPhoneNumber: '',
  addresses: [],
  additionalAddresses: [],
  openningHours: [
    {
      day: 'Sunday',
      isOpen: false,
      openTimeHours: 0,
      closeTimeHours: 0,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Monday',
      isOpen: true,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Tuesday',
      isOpen: true,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Wednesday',
      isOpen: true,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Thursday',
      isOpen: true,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Friday',
      isOpen: true,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
    {
      day: 'Saturday',
      isOpen: false,
      openTimeHours: 9,
      closeTimeHours: 6,
      openTimeMinutes: 0,
      closeTimeMinutes: 0,
    },
  ]
}
