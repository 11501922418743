export default [
  {
    shop: 'Drono Repair Shop',
    quoteNumber: 'HMAK9DSRAP',
    zipCode: '100001',
    totalPrice: '$ 650.00',
    repairType: 'Exterior - Real Door',
    numberRepairs: 576,
    sponsored: true,
    quoteText:
      'Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. ',
  },
  {
    shop: 'Drono Repair Shop',
    quoteNumber: 'HMAK9DSRAP',
    zipCode: '100001',
    totalPrice: '$ 650.00',
    repairType: 'Exterior - Real Door',
    numberRepairs: 576,
    sponsored: false,
    quoteText:
      'Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. ',
  },
  {
    shop: 'Drono Repair Shop',
    quoteNumber: 'HMAK9DSRAP',
    zipCode: '100001',
    totalPrice: '$ 650.00',
    repairType: 'Exterior - Real Door',
    numberRepairs: 576,
    sponsored: false,
    quoteText:
      'Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. ',
  },
  {
    shop: 'Drono Repair Shop',
    quoteNumber: 'HMAK9DSRAP',
    zipCode: '100001',
    totalPrice: '$ 650.00',
    repairType: 'Exterior - Real Door',
    numberRepairs: 576,
    sponsored: true,
    quoteText:
      'Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. Dear Nicolas, we’re writing in concern of your latest request about your car repair Dear Nicolas, we’re writing in concern of your latest request about your car repair. ',
  },
]
