import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import BackgroundImageOnLoad from 'background-image-on-load'
import IconConfirmedCheck from '../svg-icons/IconConfirmedCheck'

const WelcomeScreen = () => {
  const [backLoaded, setBackLoaded] = useState(false)

  return (
    <div
      className={classNames(
        'signup-container signup-business-container welcome',
        {
          loaded: backLoaded,
        }
      )}>
      <div className='column info-graphic-column' />

      <div className='column form-column'>
        <IconConfirmedCheck />

        <div>
          <h2>Welcome to Fendr Bendr! </h2>

          <p>
            We are processing your account and will notify you by email once
            your account is ready to use. In the meantime for any questions
            please reach out to us at &nbsp;
            <a href='mailto:hello@fendrbendr.com​'>hello@fendrbendr.com​</a>
          </p>
        </div>

        <Link to='/' className='button button-blue'>
          Done
        </Link>
      </div>

      <BackgroundImageOnLoad
        src={'/img/signup-business.jpg'}
        onLoadBg={() => setBackLoaded(true)}
        onError={(err) => console.log('error', err)}
      />
    </div>
  )
}

export default WelcomeScreen
