export const initialDataSchema = {
  firstName: '',
  secondName: '',
  email: '',
  password: '',
  zipCode: '',
  state: '',
  city: '',
  address: '',
  longitude: 0,
  latitude: 0,
  vehicleMake: '',
};

export const formStepsNames = ['About you', 'Address', 'Your Vehicle'];
